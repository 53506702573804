import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from '..';
import { resetImpersonaCookie } from '../../pages/Layout/utils';
import { IToken, IUser } from '../../types/auth';
import GetDomain from '../../utils/GetDomain';

export interface AuthState {
  token: IToken;
  user: IUser;
  impersonatedUser?: IUser;
  impersona: boolean;
}

const initialState: AuthState = {
  token: null,
  user: null,
  impersonatedUser: null,
  impersona: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedUser: (state, action: PayloadAction<IUser>) => {
      const cookieOptions = {
        expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
        domain: GetDomain(),
      };
      Cookies.set('doLogout', 'false', cookieOptions);
      state.user = action.payload;
    },
    unsetLoggedUser: () => {
      const cookieOptions = {
        expires: 1,
        domain: GetDomain(),
      };
      Cookies.set('doLogout', 'true', cookieOptions);
      resetImpersonaCookie(); // TODO: move this to a saga or middleware
      return {
        ...initialState,
      };
    },
    setTokenUser: (state, action: PayloadAction<IToken>) => {
      state.token = action.payload;
    },
    setImpersonatedUser: (state, action: PayloadAction<IUser>) => {
      state.impersona = true;
      state.impersonatedUser = action.payload;
    },
  },
});

export const selectCurrentUser = (state: RootState): IUser | undefined => {
  const { user, impersonatedUser, impersona } = state.auth;
  if (impersona && impersonatedUser) return impersonatedUser;
  if (user) return user;
  return undefined;
};

export const selectImpersonatedUser = (state: RootState): IUser | undefined => {
  return state.auth.impersonatedUser;
};

export const selectImpersonaState = (state: RootState): boolean => state.auth.impersona;

export const selectUserLogInStatus = (state: RootState): boolean =>
  state.auth.user && !!Cookies.get('jwtRent');

export const { setLoggedUser, unsetLoggedUser, setTokenUser, setImpersonatedUser } =
  authSlice.actions;
export default authSlice.reducer;
