import {
  filterApartmentsByDates,
  getPlanByName,
  getPlanListByType,
  getApartmentPrice,
  getApartmentData,
  getPacksList,
  togglePack,
  getTotalPacksPrice,
  getUpdatedPacksList,
  getMinFloor,
  getEmptyCellarInfo,
  checkAndSetApartments,
  getPlanByMoodAndOrFloor,
  getAvailableApartments,
  getPlanListByFloor,
  getPlanListByMood,
  getAvailablePlans,
  getDefaults,
  getFeaturesTotalPrice,
  removeFeaturesIncludedByApartment,
  sortFeaturesByLowestPrice,
} from '../functions/rentFunctions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApartmentInfo,
  Pack,
  Feature,
  ApartmentPublic,
  Plan,
  Floor,
  DefaultTypes,
  ExtraFeature,
} from '../../types/rent';
import { DateState } from './date';
import { ComponentParams, MonthsToExclude } from '../../types/info';
import { ApartmentAvailability } from '../../types/common';
import { cloneDeep } from 'lodash';
import { RootState } from '..';

export interface RentState {
  apartments: ApartmentPublic[];
  availableFeatures: { [key: string]: ExtraFeature[] };
  selectedFeatures: { [key: string]: ExtraFeature[] };
  features: Feature[];
  complex: string;
  isMultibuilding: boolean;
  packsParams: Pick<
    ComponentParams,
    'packsNoMood' | 'packsNotStandardPeriod' | 'basePackName' | 'disabledPacksInReletting'
  >;
  defaultTypes: DefaultTypes;
  type: string;
  planList: Plan[];
  plan: Plan;
  apartmentInfo: ApartmentInfo;
  soldOut: boolean;
  noAvailableOptions: boolean;
  apartmentPrice: number;
  apartmentPriceWithoutExpenses: number;
  totalPrice: number;
  totalPriceWithoutExpenses: number;
  floor: Floor;
  mood: string;
  packsList: Pack[];
  totalPacksPrice: number;
  cellarTypeName: string;
  totalParkingPrice: number;
  totalBoxPrice: number;
  totalCellarPrice: number;
  extraSpaceTotalPrice: number;
  extraSpaceTotalPriceWithoutExpenses: number;
  boxNumber?: number;
  boxMiniNumber?: number;
  coveredParkingNumber?: number;
  uncoveredParkingNumber?: number;
  doubleBoxNumber?: number;
  cellarNumber?: number;
  autoNumber?: number;
  motoNumber?: number;
  availability: ApartmentAvailability;
}
//init state
const initialState: RentState = {
  apartments: [],
  features: [],
  availableFeatures: null,
  selectedFeatures: null,
  complex: null,
  isMultibuilding: false,
  packsParams: null,
  defaultTypes: {},
  type: '',
  planList: [],
  plan: null,
  apartmentInfo: {
    id: '',
    name: '',
    cellarInfo: getEmptyCellarInfo(),
    includedPacks: [],
    totalFloors: 0,
    building: '',
    isReletting: false,
    address: '',
  },
  soldOut: false,
  noAvailableOptions: false,
  apartmentPrice: 0,
  apartmentPriceWithoutExpenses: 0,
  totalPrice: 0,
  totalPriceWithoutExpenses: 0,
  floor: {
    number: -100,
    price: 0,
    mood: '',
    availableMoods: [],
    basePrice: 0,
    apartmentName: '',
  },
  mood: '',
  packsList: [],
  totalPacksPrice: 0,
  cellarTypeName: '',
  totalBoxPrice: 0,
  totalParkingPrice: 0,
  totalCellarPrice: 0,
  extraSpaceTotalPrice: 0,
  extraSpaceTotalPriceWithoutExpenses: 0,
  availability: { number: 0 },
};

export const rentSlice = createSlice({
  name: 'rent',
  initialState,
  reducers: {
    setApartments: (
      state: any,
      action: PayloadAction<{
        apartmentPublic: ApartmentPublic[];
        daysToBeReady: number;
        monthsToExclude: MonthsToExclude[];
      }>,
    ) => {
      const apts = checkAndSetApartments(
        action.payload.apartmentPublic,
        action.payload.daysToBeReady,
        action.payload.monthsToExclude,
      );
      const types: DefaultTypes = getDefaults(cloneDeep(action.payload.apartmentPublic)); // I need an original array with not available apartments as well
      return {
        ...state,
        apartments: apts,
        defaultTypes: types,
      };
    },
    setAvailableFeatures: (state, action: PayloadAction<{ [key: string]: ExtraFeature[] }>) => {
      removeFeaturesIncludedByApartment(action.payload, state.apartmentInfo.cellarInfo);
      sortFeaturesByLowestPrice(action.payload);
      state.availableFeatures = action.payload;
    },
    setFeatures: (
      state,
      action: PayloadAction<{
        features: Feature[];
        enabledFeatures: Feature[];
        maxNumber: number;
      }>,
    ) => {
      const cellarTypeName = action.payload.enabledFeatures.find((item) => item.name === 'cellar');
      return {
        ...state,
        cellarTypeName: cellarTypeName.type,
        features: action.payload.features,
      };
    },
    setParams: (state, action: PayloadAction<ComponentParams>) => {
      const packsParams = {
        packsNotStandardPeriod: action.payload.packsNotStandardPeriod,
        packsNoMood: action.payload.packsNoMood,
        basePackName: action.payload.basePackName ? action.payload.basePackName : 'base-interior',
        disabledPacksInReletting: action.payload.disabledPacksInReletting,
      };
      state.packsParams = packsParams;
    },
    // setBuilding: (state, action: PayloadAction<string>) => {
    //   state.building = action.payload;
    // },
    setComplex: (state, action: PayloadAction<string>) => {
      state.complex = action.payload;
    },
    setIsMultibuilding: (state, action: PayloadAction<boolean>) => {
      state.isMultibuilding = action.payload;
    },
    updateApartments: (
      state,
      action: PayloadAction<{ dates: DateState; isFloorChosen: boolean; isMoodChosen: boolean }>,
    ) => {
      if (state.type !== '') {
        // I have a type
        const dates = action.payload.dates;
        const basePack = state.packsParams.basePackName;
        let soldOut = false;
        let apartments = filterApartmentsByDates([...state.apartments], dates);
        // I filter plan list by type first
        let planList = getPlanListByType(
          apartments,
          state.type,
          basePack,
          state.packsParams.packsNotStandardPeriod,
        );
        let floor = state.floor;
        let mood = state.mood;
        let aptData: ApartmentInfo = state.apartmentInfo;
        const isFloorChosen = action.payload.isFloorChosen;
        const isMoodChosen = action.payload.isMoodChosen;
        if (isFloorChosen && floor.number !== -100 && isMoodChosen && mood !== '') {
          // I filter by mood and floor number if it's chosen already
          planList = getAvailablePlans(planList, floor.number, mood);
        } else if (planList.length > 0 && isFloorChosen && floor.number !== -100) {
          // I filter by floor number only if it's chosen already
          planList = getPlanListByFloor(planList, floor.number);
        } else if (planList.length > 0 && isMoodChosen && mood !== '') {
          // I filter by mood only if it's chosen already
          planList = getPlanListByMood(planList, mood);
        }
        let plan = planList[0];
        if (planList.length === 0) {
          // Nothing is found for my request so I filter only by type first
          soldOut = true;
          apartments = getAvailableApartments([...state.apartments]);
          planList = getPlanListByType(
            apartments,
            state.type,
            basePack,
            state.packsParams.packsNotStandardPeriod,
          );
          plan = planList[0];
          if (plan) {
            // If there is plan for my type I can retrieve floor and mood which costs less
            floor = getMinFloor(plan);
            mood = floor.mood;
            aptData = getApartmentData(
              state.apartments,
              plan,
              floor,
              state.features,
              state.cellarTypeName,
            );
          } else {
            // I don't have any plan so it means this type of apartment is not available at all
            return {
              ...state,
              noAvailableOptions: true,
              totalPrice: 0,
            };
          }
        } else {
          // I've found some plans for my request, getting floor which costs less
          const newFloor = getMinFloor(
            plan,
            isMoodChosen ? mood : null,
            isFloorChosen ? floor.number : null,
          );
          floor = newFloor;
          //Take mood of a chosen apartment -> or which was chosen before (reletting) or which cost less (greenfield)
          if (!isMoodChosen) {
            mood = floor.mood;
          }
          aptData = getApartmentData(
            state.apartments,
            plan,
            floor,
            state.features,
            state.cellarTypeName,
          );
        }
        const packsList = getPacksList(
          mood,
          dates,
          plan.extraInfo,
          state.packsParams.packsNotStandardPeriod,
          basePack,
          state.packsParams.packsNoMood,
          aptData.includedPacks,
          state.packsParams.disabledPacksInReletting,
          aptData.isReletting,
        );
        const apartmentPrice = getApartmentPrice(mood, plan, floor, aptData.cellarInfo, basePack);
        const apartmentPriceWithoutExpenses = getApartmentPrice(
          mood,
          plan,
          floor,
          aptData.cellarInfo,
          basePack,
          false,
        );
        const totalPacksPrice = getTotalPacksPrice(packsList);
        const totalPrice = totalPacksPrice + apartmentPrice + state.extraSpaceTotalPrice;
        const totalPriceWithoutExpenses =
          totalPacksPrice +
          apartmentPriceWithoutExpenses +
          state.extraSpaceTotalPriceWithoutExpenses;
        return {
          ...state,
          planList: planList,
          packsList: packsList,
          plan: plan,
          apartmentInfo: aptData,
          soldOut: soldOut,
          mood: mood,
          floor: floor,
          apartmentPrice: apartmentPrice,
          apartmentPriceWithoutExpenses: apartmentPriceWithoutExpenses,
          totalPacksPrice: totalPacksPrice,
          totalPrice: totalPrice,
          totalPriceWithoutExpenses,
        };
      } else {
        return { ...state };
      }
    },
    setType: (state, action: PayloadAction<{ type: string; dates: DateState }>) => {
      const type = action.payload.type;
      const dates = action.payload.dates;
      const basePack = state.packsParams.basePackName;
      let soldOut = false;
      let apartments = filterApartmentsByDates([...state.apartments], dates);
      let planList = getPlanListByType(
        apartments,
        type,
        basePack,
        state.packsParams.packsNotStandardPeriod,
      );
      if (planList.length === 0) {
        soldOut = true;
        apartments = getAvailableApartments([...state.apartments]);
        planList = getPlanListByType(
          apartments,
          type,
          basePack,
          state.packsParams.packsNotStandardPeriod,
        );
      }
      const plan = planList[0];
      if (plan) {
        const floor = getMinFloor(plan, null, null);
        const aptData: ApartmentInfo = getApartmentData(
          state.apartments,
          plan,
          floor,
          state.features,
          state.cellarTypeName,
        );
        //Take mood of a chosen apartment -> or which was chosen before (reletting) or which cost less (greenfield)
        const mood = floor.mood;
        const packsList = getPacksList(
          mood,
          dates,
          plan.extraInfo,
          state.packsParams.packsNotStandardPeriod,
          basePack,
          state.packsParams.packsNoMood,
          aptData.includedPacks,
          state.packsParams.disabledPacksInReletting,
          aptData.isReletting,
        );

        const apartmentPrice = getApartmentPrice(mood, plan, floor, aptData.cellarInfo, basePack);
        const apartmentPriceWithoutExpenses = getApartmentPrice(
          mood,
          plan,
          floor,
          aptData.cellarInfo,
          basePack,
          false,
        );
        const totalPacksPrice = getTotalPacksPrice(packsList);
        const totalPrice = totalPacksPrice + apartmentPrice + state.extraSpaceTotalPrice;
        const totalPriceWithoutExpenses =
          totalPacksPrice +
          apartmentPriceWithoutExpenses +
          state.extraSpaceTotalPriceWithoutExpenses;
        return {
          ...state,
          type: type,
          planList: planList,
          packsList: packsList,
          plan: plan,
          apartmentInfo: aptData,
          soldOut: soldOut,
          noAvailableOptions: false,
          mood: mood,
          floor: floor,
          apartmentPrice: apartmentPrice,
          apartmentPriceWithoutExpenses,
          totalPacksPrice: totalPacksPrice,
          totalPrice: totalPrice,
          totalPriceWithoutExpenses,
        };
      } else {
        return {
          ...state,
          type: type,
          noAvailableOptions: true,
          totalPrice: 0,
        };
      }
    },
    setFloor: (
      state,
      action: PayloadAction<{ floorNumber: number; dates: DateState; isMoodChosen: boolean }>,
    ) => {
      const floorNumber: number = action.payload.floorNumber;
      const basePack = state.packsParams.basePackName;
      const dates = action.payload.dates;
      const isMoodChosen = action.payload.isMoodChosen;
      const planList = getPlanListByFloor(state.planList, floorNumber);
      const plan = action.payload.isMoodChosen
        ? getPlanByMoodAndOrFloor(planList, floorNumber, state.mood)
        : getPlanByMoodAndOrFloor(planList, floorNumber, null);

      const floor = isMoodChosen
        ? getMinFloor(plan, state.mood, floorNumber)
        : getMinFloor(plan, null, floorNumber);
      const mood = isMoodChosen ? state.mood : floor.mood;
      const aptData = getApartmentData(
        state.apartments,
        plan,
        floor,
        state.features,
        state.cellarTypeName,
      );
      const packsList = getPacksList(
        mood,
        dates,
        plan.extraInfo,
        state.packsParams.packsNotStandardPeriod,
        basePack,
        state.packsParams.packsNoMood,
        aptData.includedPacks,
        state.packsParams.disabledPacksInReletting,
        aptData.isReletting,
      );
      const apartmentPrice = getApartmentPrice(mood, plan, floor, aptData.cellarInfo, basePack);
      const apartmentPriceWithoutExpenses = getApartmentPrice(
        mood,
        plan,
        floor,
        aptData.cellarInfo,
        basePack,
        false,
      );
      const totalPacksPrice = getTotalPacksPrice(packsList);
      const totalPrice = totalPacksPrice + apartmentPrice + state.extraSpaceTotalPrice;
      const totalPriceWithoutExpenses =
        totalPacksPrice + apartmentPriceWithoutExpenses + state.extraSpaceTotalPriceWithoutExpenses;
      return {
        ...state,
        floor: floor,
        plan: plan,
        apartmentInfo: aptData,
        mood: mood,
        packsList: packsList,
        totalPacksPrice: totalPacksPrice,
        apartmentPrice: apartmentPrice,
        apartmentPriceWithoutExpenses,
        totalPrice: totalPrice,
        totalPriceWithoutExpenses,
      };
    },
    setMood: (
      state,
      action: PayloadAction<{ mood: string; dates: DateState; isFloorChosen: boolean }>,
    ) => {
      const mood = action.payload.mood;
      const isFloorChosen = action.payload.isFloorChosen;
      const basePack = state.packsParams.basePackName;
      const planList = getPlanListByMood(state.planList, mood);
      const plan = isFloorChosen
        ? getPlanByMoodAndOrFloor(planList, state.floor.number, mood)
        : getPlanByMoodAndOrFloor(planList, null, mood);

      const floor = isFloorChosen
        ? getMinFloor(plan, mood, state.floor.number)
        : getMinFloor(plan, mood, null);
      const aptData = getApartmentData(
        state.apartments,
        plan,
        floor,
        state.features,
        state.cellarTypeName,
      );
      const packsList = getPacksList(
        mood,
        action.payload.dates,
        plan.extraInfo,
        state.packsParams.packsNotStandardPeriod,
        basePack,
        state.packsParams.packsNoMood,
        aptData.includedPacks,
        state.packsParams.disabledPacksInReletting,
        aptData.isReletting,
      );
      const totalPackPrice = getTotalPacksPrice(packsList);
      const apartmentPrice = getApartmentPrice(mood, plan, floor, aptData.cellarInfo, basePack);
      const apartmentPriceWithoutExpenses = getApartmentPrice(
        mood,
        plan,
        floor,
        aptData.cellarInfo,
        basePack,
        false,
      );
      const totalPrice = totalPackPrice + apartmentPrice + state.extraSpaceTotalPrice;
      const totalPriceWithoutExpenses =
        totalPackPrice + apartmentPriceWithoutExpenses + state.extraSpaceTotalPriceWithoutExpenses;
      return {
        ...state,
        apartmentInfo: aptData,
        mood: mood,
        plan: plan,
        floor: floor,
        apartmentPrice: apartmentPrice,
        apartmentPriceWithoutExpenses,
        totalPrice: totalPrice,
        totalPacksPrice: totalPackPrice,
        packsList: packsList,
        totalPriceWithoutExpenses,
      };
    },
    setPlan: (state, action: PayloadAction<{ planName: string; dates: DateState }>) => {
      const plan = getPlanByName(state.planList, action.payload.planName);
      const floor = getMinFloor(plan, state.mood, state.floor.number);
      const basePack = state.packsParams.basePackName;

      const aptData = getApartmentData(
        state.apartments,
        plan,
        floor,
        state.features,
        state.cellarTypeName,
      );
      const apartmentPrice = getApartmentPrice(
        state.mood,
        plan,
        floor,
        aptData.cellarInfo,
        basePack,
      );
      const apartmentPriceWithoutExpenses = getApartmentPrice(
        state.mood,
        plan,
        floor,
        aptData.cellarInfo,
        basePack,
        false,
      );
      const packsList = getPacksList(
        state.mood,
        action.payload.dates,
        plan.extraInfo,
        state.packsParams.packsNotStandardPeriod,
        basePack,
        state.packsParams.packsNoMood,
        aptData.includedPacks,
        state.packsParams.disabledPacksInReletting,
        aptData.isReletting,
      );
      const totalPackPrice = getTotalPacksPrice(packsList);
      const totalPrice = totalPackPrice + apartmentPrice + state.extraSpaceTotalPrice;
      const totalPriceWithoutExpenses =
        totalPackPrice + apartmentPriceWithoutExpenses + state.extraSpaceTotalPriceWithoutExpenses;
      state.plan = plan;
      state.floor = floor;
      state.apartmentInfo = aptData;
      state.apartmentPrice = apartmentPrice;
      state.apartmentPriceWithoutExpenses = apartmentPriceWithoutExpenses;
      state.totalPrice = totalPrice;
      state.totalPriceWithoutExpenses = totalPriceWithoutExpenses;
      state.totalPacksPrice = totalPackPrice;
      state.packsList = packsList;
    },
    setPack: (state, action: PayloadAction<string>) => {
      const packName = action.payload;
      const list = togglePack(packName, [...state.packsList]);
      const totalPackPrice = getTotalPacksPrice(list);
      const totalPrice = totalPackPrice + state.apartmentPrice + state.extraSpaceTotalPrice;
      const totalPriceWithoutExpenses =
        totalPackPrice +
        state.apartmentPriceWithoutExpenses +
        state.extraSpaceTotalPriceWithoutExpenses;
      state.packsList = list;
      state.totalPacksPrice = totalPackPrice;
      state.totalPrice = totalPrice;
      state.totalPriceWithoutExpenses = totalPriceWithoutExpenses;
    },
    setPacksList: (state, action: PayloadAction<DateState>) => {
      const list = getUpdatedPacksList(
        state.packsList,
        action.payload,
        state.packsParams.packsNotStandardPeriod,
      );
      const totalPackPrice = getTotalPacksPrice(list);
      const totalPrice = totalPackPrice + state.apartmentPrice + state.extraSpaceTotalPrice;
      const totalPriceWithoutExpenses =
        totalPackPrice +
        state.apartmentPriceWithoutExpenses +
        state.extraSpaceTotalPriceWithoutExpenses;
      state.packsList = list;
      state.totalPacksPrice = totalPackPrice;
      state.totalPrice = totalPrice;
      state.totalPriceWithoutExpenses = totalPriceWithoutExpenses;
    },
    removeSelectedFeature: (state, action: PayloadAction<string>) => {
      state.selectedFeatures[action.payload].pop();
      const extraSpaceTotalPrice = getFeaturesTotalPrice(state.selectedFeatures);
      const extraSpaceTotalPriceWithoutExpenses = getFeaturesTotalPrice(
        state.selectedFeatures,
        false,
      );
      const totalPrice = state.totalPacksPrice + state.apartmentPrice + extraSpaceTotalPrice;
      const totalPriceWithoutExpenses =
        state.totalPacksPrice +
        state.apartmentPriceWithoutExpenses +
        extraSpaceTotalPriceWithoutExpenses;
      state.extraSpaceTotalPrice = extraSpaceTotalPrice;
      state.extraSpaceTotalPriceWithoutExpenses = extraSpaceTotalPriceWithoutExpenses;
      state.totalPrice = totalPrice;
      state.totalPriceWithoutExpenses = totalPriceWithoutExpenses;
    },
    resetSelectedFeatures: (state) => {
      state.selectedFeatures = null;
      const totalPrice = state.totalPacksPrice + state.apartmentPrice;
      const totalPriceWithoutExpenses = state.totalPacksPrice + state.apartmentPriceWithoutExpenses;
      state.extraSpaceTotalPrice = 0;
      state.extraSpaceTotalPriceWithoutExpenses = 0;
      state.totalPrice = totalPrice;
      state.totalPriceWithoutExpenses = totalPriceWithoutExpenses;
    },
    addSelectedFeature: (state, action: PayloadAction<string>) => {
      if (state?.selectedFeatures) {
        state.selectedFeatures = {
          ...state.selectedFeatures,
          [action.payload]: [
            ...(state.selectedFeatures[action.payload] || []),
            state.availableFeatures[action.payload][
              state.selectedFeatures?.[action.payload]?.length || 0
            ],
          ],
        };
      } else {
        state.selectedFeatures = {
          [action.payload]: [state.availableFeatures[action.payload][0]],
        };
      }
      const extraSpaceTotalPrice = getFeaturesTotalPrice(state.selectedFeatures);
      const extraSpaceTotalPriceWithoutExpenses = getFeaturesTotalPrice(
        state.selectedFeatures,
        false,
      );
      const totalPrice = state.totalPacksPrice + state.apartmentPrice + extraSpaceTotalPrice;
      const totalPriceWithoutExpenses =
        state.totalPacksPrice +
        state.apartmentPriceWithoutExpenses +
        extraSpaceTotalPriceWithoutExpenses;
      state.extraSpaceTotalPrice = extraSpaceTotalPrice;
      state.extraSpaceTotalPriceWithoutExpenses = extraSpaceTotalPriceWithoutExpenses;
      state.totalPrice = totalPrice;
      state.totalPriceWithoutExpenses = totalPriceWithoutExpenses;
    },
    setAvailability: (state, action: PayloadAction<ApartmentAvailability>) => {
      state.availability = action.payload;
    },
  },
});

export const getSelectedFeatures = (state: RootState): { [key: string]: ExtraFeature[] } =>
  state.rent.selectedFeatures;
export const getIncludedFeature = (state: RootState): Feature =>
  state.rent.apartmentInfo.cellarInfo;
export const selectAvailableFeatures = (state: RootState): { [key: string]: ExtraFeature[] } =>
  state.rent.availableFeatures;

export const {
  updateApartments,
  setApartments,
  setFeatures,
  // setBuilding,
  setComplex,
  setIsMultibuilding,
  setFloor,
  setMood,
  setPack,
  setPacksList,
  setAvailableFeatures,
  setParams,
  setPlan,
  setType,
  setAvailability,
  removeSelectedFeature,
  addSelectedFeature,
  resetSelectedFeatures,
} = rentSlice.actions;
export default rentSlice.reducer;
