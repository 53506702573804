import { getPackName, includesOneOf } from '../../utils/utils';
import { DateState } from '../slices/date';
import { getMonthsDiff } from './dateFunctions';
import cloneDeep from 'lodash/cloneDeep';

import {
  Feature,
  Pack,
  Floor,
  ApartmentPublic,
  Plan,
  ExtraInfo,
  ApartmentInfo,
  Mood,
  DefaultTypes,
  ExtraFeature,
} from '../../types/rent';
import { DateTime, Interval } from 'luxon';
import ApartmentPublicService from '../services/apartmentPublicService';
import { MonthsToExclude } from '../../types/info';

const MONTHS_IN_YEAR = 12;
const aptPublicService = new ApartmentPublicService();

const isAvailable = (apartment: ApartmentPublic): boolean => {
  return apartment.available && apartment.active;
};

/**
 * returns available apartments
 * @param {*} apartments
 */
export const getAvailableApartments = (apartments: ApartmentPublic[]): ApartmentPublic[] => {
  const apartmentsAvailable = [...apartments].filter(
    (apartment) => isAvailable(apartment),
    // (apartment) => apartment.available && apartment.active && apartment.enabled_web,
  );
  return apartmentsAvailable;
};

/**
 * returns a list of apartments with "new" flag set and availableFrom which is the first date from now when apartment is free.
 * @param {*} apartments
 */

export const checkAndSetApartments = (
  apartments: ApartmentPublic[],
  daysToBeReady: number,
  monthsToExclude: Array<MonthsToExclude>,
): ApartmentPublic[] => {
  const updatedApts: ApartmentPublic[] = [];
  cloneDeep(apartments).forEach((apt: ApartmentPublic) => {
    aptPublicService.buildAptPublic(apt, daysToBeReady, monthsToExclude);
    updatedApts.push(apt);
  });
  const availableApartments = getAvailableApartments(updatedApts);
  return availableApartments;
};

export const getDefaults = (apartments: ApartmentPublic[]): DefaultTypes => {
  let types: DefaultTypes = {};
  apartments.forEach((ap) => {
    const apType = ap.plan?.typology?.name;
    const apBuilding = ap.sides && ap.sides[0]?.building?.name;
    if (apType && !types[apType]) {
      const moods = ap.plan.typology.formulas.map((el) => el.name);
      types = {
        ...types,
        [apType]: {
          mood: moods,
          buildings: types[apType]?.buildings || [],
        },
      };
    }
    if (types[apType] && apBuilding && !types[apType].buildings.includes(apBuilding)) {
      types[apType].buildings.push(apBuilding);
    }
  });
  return types;
};

/**
 * returns a list of apartments available for chosen period
 * @param {*} apartments
 * @param {*} dates
 */
// TODO: fix the logic here considering apartments flags once defined
export const filterApartmentsByDates = (
  apartments: ApartmentPublic[],
  dates: DateState,
): ApartmentPublic[] => {
  return apartments.filter(
    (apt) =>
      !aptPublicService.isOccupied(apt, dates) &&
      isAvailable(apt) &&
      aptPublicService.isReady(apt, dates),
  );
};

/**
 * returns the first available date for check in
 * @param {*} apartments
 */

export const getFirstAvailableStartDate = (apartments: ApartmentPublic[]): DateTime => {
  return aptPublicService.getFirstAvailableStartDate(apartments);
};

/**
 * returns the month price of the base interior pack
 * @param {*} mood
 * @param {*} extraInfo
 */
export const getInteriorPrice = (
  mood: string,
  extraInfo: ExtraInfo,
  basePackName: string,
): number => {
  let interiorPrice = 0;
  for (const packInfo in extraInfo) {
    if (
      packInfo.toLowerCase().includes(mood.toLowerCase()) &&
      packInfo.toLowerCase().includes(basePackName)
    ) {
      interiorPrice = <number>extraInfo[packInfo] / MONTHS_IN_YEAR;
    }
  }
  return interiorPrice;
};

/**
 * returns the base apartment price per month included interior price
 * @param {*} mood
 * @param {*} plan
 * @param {*} floor
 */
export const getApartmentPrice = (
  mood: string,
  plan: Plan,
  floor: Floor,
  cellarInfo: Feature,
  basePackName: string,
  includeExpense = true,
): number => {
  const rentPrice = floor.basePrice + (includeExpense ? floor.monthExpense : 0);
  const cellarPrice = cellarInfo
    ? cellarInfo.price + (includeExpense ? cellarInfo.expenses : 0)
    : 0;
  const interiorPrice = getInteriorPrice(mood, plan.extraInfo, basePackName);
  const totalBasePrice = rentPrice + interiorPrice + cellarPrice / MONTHS_IN_YEAR;
  return totalBasePrice;
};

export const getEmptyCellarInfo = (): Feature => {
  return {
    id: '',
    name: '',
    type: '',
    building: null,
    price: 0,
    floor: 0,
    notes: '',
    code: '',
    meters: '',
    width: '',
    length: '',
    visible: false,
    available: false,
    createdOn: '',
    status: '',
    sub: '',
    expenses: 0,
    isEmpty: true,
  };
};

/**
 * returns info about included cellar
 * @param {*} features
 * @param {*} type
 * @param {*} aptCodeId
 * @param {*} buildingID
 */
export const getIncludedCellarInfo = (
  features: Feature[],
  type: string,
  aptCodeId: string,
  buildingID: string,
): Feature => {
  let featureIncludedInfo: Feature;
  if (aptCodeId && !!type) {
    featureIncludedInfo = features.find(
      (feature: Feature) =>
        feature.type === type &&
        feature.extraInfo?.id_appartamento === aptCodeId &&
        feature.building.id === buildingID,
    );
  }
  return featureIncludedInfo || getEmptyCellarInfo();
};

/**
 * returns the apartment id
 * @param {*} apartments
 * @param {*} plan
 * @param {*} floor
 */
export const getApartmentData = (
  apartments: ApartmentPublic[],
  plan: Plan,
  floor: Floor,
  features: Feature[],
  type: string,
): ApartmentInfo => {
  const apt = apartments.find((apartment) => {
    return apartment.plan.name === plan.name && apartment.name === floor.apartmentName;
  });
  const buildingID = apt.sides[0]?.building.id;
  const building = apt.sides[0]?.building;
  if (apt) {
    return {
      id: apt.id,
      name: apt.name.toUpperCase(),
      cellarInfo: getIncludedCellarInfo(features, type, apt.code, buildingID),
      includedPacks: apt.packsList,
      totalFloors: apt.sides[0]?.building.floors,
      address: building.address,
      building: building.name,
      isReletting: apt.movements.some(
        (movement) => movement.status === 'rogitato' || movement.status === 'scaduto',
      ),
      buildingID,
    };
  } else return null;
};

/**
 * sort features by lowest price
 * @param {*} features
 */
export const sortFeaturesByLowestPrice = (features: { [key: string]: ExtraFeature[] }): void => {
  Object.keys(features).forEach((featureType) => {
    return features[featureType]?.sort((featA, featB) => {
      return featA.totalPrice.price - featB.totalPrice.price;
    });
  });
};

/**
 * sort features by lowest price
 * @param {*} features
 */
export const removeFeaturesIncludedByApartment = (
  features: {
    [key: string]: ExtraFeature[];
  },
  featureIncluded: Feature,
): void => {
  if (featureIncluded?.id) {
    features[featureIncluded.type] = features[featureIncluded.type]?.filter(
      (feature) => feature.id !== featureIncluded.id,
    );
  }
};

/**
 * returns the min price of base interior pack
 * @param {*} extraInfo
 */
export const getMinInteriorPriceAndMood = (
  extraInfo: ExtraInfo,
  basePackName: string,
): { interiorPrice: number; mood: string } => {
  let interiorPrice = Number.MAX_SAFE_INTEGER;
  let mood = '';
  for (const packInfo in extraInfo) {
    if (packInfo.toLowerCase().includes(basePackName)) {
      if (interiorPrice > extraInfo[packInfo]) {
        interiorPrice = <number>extraInfo[packInfo];
        mood = packInfo.split('_')[0];
      }
    }
  }
  return { interiorPrice: interiorPrice / MONTHS_IN_YEAR, mood: mood };
};

const getAllAvailableMoodsForFloor = (plan: Plan) => {
  const moodList: string[] = [];
  plan.typology.formulas.forEach((formula) => {
    if (!moodList.includes(formula.name.toLowerCase())) {
      moodList.push(formula.name.toLowerCase());
    }
  });
  return moodList;
};

const getPackPrice = (pack: string, extraInfo: ExtraInfo, mood: string) => {
  let pricePerMonth = 0;
  for (const moodPack in extraInfo) {
    if (moodPack.includes(mood) && moodPack.replace(/-/g, '').includes(pack.replace(/-/g, ''))) {
      pricePerMonth = <number>extraInfo[moodPack] / MONTHS_IN_YEAR;
    }
  }
  return pricePerMonth;
};

/**
 * returns basic information about floor (number, price) and plan min price
 * @param {*} apartments
 */
export const getFloorInfo = (
  apartments: ApartmentPublic[],
  basePackName: string,
  notValidPacks: string[],
): { minPrice: number; floors: Floor[]; minMood: string } => {
  const floors: Floor[] = [];
  let minPrice = Number.MAX_SAFE_INTEGER;
  let minMood = '';
  apartments.forEach((apt) => {
    const monthExpense = apt.extraInfo['spese-condominiali'] / MONTHS_IN_YEAR;
    let price = apt.price + monthExpense;
    const extraInfo = apt.plan.extraInfo;
    let availableMoods = [];
    let mood = '';
    if (apt.mood) {
      const aptMood = apt.mood.toLowerCase().replace(/\s/g, '-');
      price += <number>extraInfo[aptMood + '_' + basePackName] / MONTHS_IN_YEAR;
      availableMoods.push(aptMood); //l'unico mood disponibile per questo piano
      mood = aptMood;
    } else {
      const minPriceAndMood = getMinInteriorPriceAndMood(extraInfo, basePackName);
      price += minPriceAndMood.interiorPrice;
      mood = minPriceAndMood.mood;
      //trova min base interior
      availableMoods = getAllAvailableMoodsForFloor(apt.plan);
    }
    if (apt.packsList?.length && apt.mood) {
      //mood dev'essere per forza se ci sono dei pack
      let packsTotalPrice = 0;
      apt.packsList.forEach((pack: string) => {
        if (!notValidPacks.includes(pack)) {
          packsTotalPrice += getPackPrice(pack, extraInfo, apt.mood);
        }
      });
      price += packsTotalPrice;
    }
    if (minPrice > price) {
      minPrice = price;
      minMood = mood;
    }
    floors.push({
      number: apt.floor,
      price: price,
      basePrice: apt.price,
      monthExpense: monthExpense,
      availableMoods: availableMoods,
      mood: mood,
      apartmentName: apt.name,
    });
  });
  floors.sort((a, b) => {
    return a.number - b.number;
  });
  return { minPrice, floors, minMood };
};

/**
 * returns a plan list filtered by typology and sorted by plan min price
 * @param {*} apartments
 * @param {*} type
 */
export const getPlanListByType = (
  apartments: ApartmentPublic[],
  type: string,
  basePackName: string,
  notValidPacks: string[],
): Plan[] => {
  const filteredOnceApts = apartments.filter((apt) => {
    return apt.plan.typology.name === type;
  });
  const plans: { [key: string]: Plan } = {};
  [...filteredOnceApts].forEach((apartment) => {
    if (!Object.keys(plans).includes(apartment.plan.name)) {
      const plan: Plan = cloneDeep(apartment.plan);
      const filteredTwiceApts = [...filteredOnceApts].filter((apt) => {
        return apt.plan.name === plan.name;
      });
      const floorInfo = getFloorInfo(filteredTwiceApts, basePackName, notValidPacks);
      plan.floors = floorInfo.floors;
      plan.minPrice = floorInfo.minPrice;
      plan.minMood = floorInfo.minMood;
      plan.extraGuest = 1;
      plans[plan.name] = plan;
    }
  });
  const planList: Plan[] = [];
  for (const planName in plans) {
    planList.push(plans[planName]);
  }

  planList.sort((a, b) => {
    return a.minPrice - b.minPrice;
  });
  return planList;
};

/**
 * returns the plan with specified name
 * @param {*} planList
 * @param {*} planName
 */
export const getPlanByName = (planList: Plan[], planName: string): Plan => {
  const plan = planList.find((planItem) => planItem.name === planName);
  return plan;
};

export const getPlanListByFloor = (planList: Plan[], floorNumber: number): Plan[] => {
  let filteredPlanList: Plan[] = [];
  if (planList && planList.length) {
    filteredPlanList = [...planList].filter((pl: Plan) => {
      if (floorNumber !== null) {
        const floor = pl.floors.find((floorItem) => floorItem.number === floorNumber);
        return floor !== undefined;
      } else {
        //non dovrei mai essere qui...
        return false;
      }
    });
  }
  return filteredPlanList;
};

export const getPlanListByMood = (planList: Plan[], mood: string): Plan[] => {
  let filteredPlanList: Plan[] = [];
  if (planList && planList.length) {
    filteredPlanList = [...planList].filter((pl: Plan) => {
      if (mood !== null) {
        const floor = pl.floors.find((floorItem) => floorItem.availableMoods.includes(mood));
        return floor !== undefined;
      } else {
        //non dovrei mai essere qui...
        return false;
      }
    });
  }
  return filteredPlanList;
};

/**
 * returns the plan which has specified mood and/or floor min price among the other plans
 * @param {*} planList
 * @param {*} floorNumber
 * @param {*} mood
 */
export const getPlanByMoodAndOrFloor = (
  planList: Plan[],
  floorNumber: number,
  mood: string,
): Plan => {
  let plan: Plan;
  if (planList && planList.length) {
    let minPrice = Number.MAX_SAFE_INTEGER;
    planList.forEach((pl: Plan) => {
      if (floorNumber !== -100 && floorNumber !== null) {
        //there is floor
        const floor: Floor = pl.floors.find(
          (floorItem) =>
            floorItem.number === floorNumber && (!mood || floorItem.availableMoods.includes(mood)),
        );
        if (floor !== undefined && floor.price < minPrice) {
          minPrice = floor.price;
          plan = pl;
        }
      } else if (mood) {
        //there is no floor, search by mood
        const floorsWithMood = pl.floors.filter((floorItem) =>
          floorItem.availableMoods.includes(mood),
        );
        if (floorsWithMood.length) {
          floorsWithMood.forEach((fl) => {
            if (fl.price < minPrice) {
              minPrice = fl.price;
              plan = pl;
            }
          });
        }
      } else {
        //shouldn't be here
        plan = planList[0];
      }
    });
  }
  return plan;
};

/**
 * returns the minimum price of an apartment with a specified plan
 * @param {*} plan
 */
export const getMinPrice = (plan: Plan): number => {
  const floors: Floor[] = plan.floors;
  return Math.min(...floors.map((floor) => floor.price));
};

/**
 * returns the floor with min price
 * @param {*} plan
 */
export const getMinFloor = (plan: Plan, mood?: string, floorNumber?: number): Floor => {
  const floors: Floor[] = plan.floors;
  let minPrice = Number.MAX_SAFE_INTEGER;
  let minFloor: Floor;
  floors.forEach((floor) => {
    if (
      minPrice > floor.price &&
      (!mood || floor.availableMoods.includes(mood)) &&
      (!floorNumber || floor.number === floorNumber)
    ) {
      minPrice = floor.price;
      minFloor = floor;
    }
  });
  return minFloor;
};

const getMonths = (dates: DateState) => {
  const start = DateTime.fromMillis(dates.start.timestamp);
  const end = DateTime.fromMillis(dates.end.timestamp);
  return Math.round(Interval.fromDateTimes(start, end).length('months'));
};

/**
 * returns the list of packs that can be reused
 * @param {*} includedPacks
 * @param {*} disposablePacksList
 */
const getValidIncludedPacks = (includedPacks: string[], disposablePacksList: string[]) => {
  const validPacks: string[] = includedPacks.filter((pack) => {
    return !disposablePacksList.includes(pack);
  });
  return validPacks;
};

/**
 * returns the list of packs with respective info
 * @param {*} mood
 * @param {*} dates
 * @param {*} extraInfo
 */
export const getPacksList = (
  mood: string,
  dates: DateState,
  extraInfo: ExtraInfo,
  packsNotStandardPeriod: string[],
  basePackName: string,
  packsNoMood: string[],
  includedPacks: string[],
  disabledPacksInReletting: string[],
  isReletting?: boolean,
): Pack[] => {
  const period = getMonths(dates);
  const packsList: Pack[] = [];
  for (const pack in extraInfo) {
    if (
      pack.toLowerCase().includes(mood.toLowerCase()) ||
      includesOneOf(pack.toLowerCase(), packsNoMood)
    ) {
      const packName = getPackName(pack);
      let pricePerMonth = 0;
      let yearPackPrice = 0;
      if (
        isReletting &&
        disabledPacksInReletting?.includes(packName) &&
        !includedPacks?.includes(packName)
      ) {
        continue;
      }
      if (includesOneOf(pack.toLowerCase(), packsNotStandardPeriod)) {
        yearPackPrice = <number>extraInfo[pack];
        pricePerMonth = yearPackPrice / period;
      } else if (pack.toLowerCase().includes(basePackName)) {
        continue;
      } else {
        yearPackPrice = <number>extraInfo[pack];
        pricePerMonth = yearPackPrice / MONTHS_IN_YEAR;
      }

      const validIncludedPacks = getValidIncludedPacks(includedPacks, packsNotStandardPeriod);

      packsList.push({
        name: packName,
        price: pricePerMonth,
        active: validIncludedPacks.includes(packName) ? true : false,
        alreadyIncluded: validIncludedPacks.includes(packName),
        yearPackPrice,
      });
    }
  }
  return packsList.sort((pack1, pack2) => {
    if (pack1.alreadyIncluded && !pack2.alreadyIncluded) {
      return 1;
    } else if (pack2.alreadyIncluded && !pack1.alreadyIncluded) {
      return -1;
    } else {
      return 0;
    }
  });
};

/**
 * return packs list with enabled/disabled pack
 * @param {*} packName
 * @param {*} packList
 */
export const togglePack = (packName: string, packList: Pack[]): Pack[] => {
  packList.forEach((pack) => {
    if (pack.name === packName) pack.active = !pack.active;
  });
  return packList;
};

/**
 * returns the total price of activated packs
 * @param {*} packsList
 */
export const getTotalPacksPrice = (packsList: Pack[]): number => {
  let totalPack = 0;
  if (packsList && packsList.length) {
    packsList.forEach((pack) => {
      if (pack.active) totalPack += pack.price;
    });
  }
  return totalPack;
};

/**
 * returns the updated packs list
 * @param {*} packsList
 * @param {*} dates
 */
export const getUpdatedPacksList = (
  packsList: Pack[],
  dates: DateState,
  packNames: string[],
): Pack[] => {
  const period = getMonths(dates);
  let updatedPackList: Pack[] = [];
  if (packsList?.length) {
    updatedPackList = [...packsList];
    packNames.forEach((packName) => {
      let key = 0;
      const pack = updatedPackList.find((pk, index) => {
        key = index;
        return pk.name === packName;
      });
      if (pack) {
        pack.price = pack.yearPackPrice / period;
        updatedPackList[key] = pack;
      }
    });
  }
  return updatedPackList;
};

/**
 * returns the total price for extra space
 */
export const getFeaturesTotalPrice = (
  selectedFeaturesList: {
    [key: string]: ExtraFeature[];
  },
  includeExpense = true,
): number => {
  return selectedFeaturesList
    ? Object.keys(selectedFeaturesList).reduce((acc, value) => {
        acc += selectedFeaturesList[value].reduce((secondAcc, feature) => {
          secondAcc += feature.totalPrice.price + (includeExpense ? feature.totalPrice.expense : 0);
          return secondAcc;
        }, 0);
        return acc;
      }, 0)
    : 0;
};

/**
 * returns the total expense for extra space
 */
export const getFeaturesTotalExpense = (selectedFeaturesList: {
  [key: string]: ExtraFeature[];
}): number => {
  return selectedFeaturesList
    ? Object.keys(selectedFeaturesList).reduce((acc, value) => {
        acc += selectedFeaturesList[value].reduce((secondAcc, feature) => {
          secondAcc += feature.totalPrice.expense;
          return secondAcc;
        }, 0);
        return acc;
      }, 0)
    : 0;
};

/**
 * returns the first apartment available after chosen dates considering stay period and apartment type
 * @param {*} apartmentsList
 * @param {*} type
 * @param {*} dates
 */
export const getFirstAvailableApartment = (
  apartmentsList: ApartmentPublic[],
  type: string,
  dates: DateState,
): ApartmentPublic => {
  let filteredApts = getAvailableApartments(apartmentsList);
  filteredApts = apartmentsList.filter((apt) => {
    return apt.plan.typology.name === type;
  });

  const stayPeriodInMonth = getMonthsDiff(dates.start.date, dates.end.date);
  let apartment: ApartmentPublic = null;
  filteredApts.forEach((el) => {
    const apt = cloneDeep(el);
    //I need to find the first available apartment for chosen dates considering stay period
    const aptAvailableFrom = aptPublicService.getFirstValidFreeDate(apt, dates.start.date);
    if (
      !apartment ||
      aptPublicService.getDateWithoutTime(aptAvailableFrom) <
        aptPublicService.getDateWithoutTime(apartment.availableFrom)
    ) {
      apt.availableFrom = aptAvailableFrom;
      apt.availableTo = aptAvailableFrom.plus({ month: stayPeriodInMonth });
      apartment = apt;
    }
  });
  return apartment;
};

export const getAvailablePlans = (planList: Plan[], floorNumber: number, mood: string): Plan[] => {
  const availablePlanList = planList.filter((plan) => {
    return plan.floors.some((floor) => {
      return floor.number === floorNumber && floor.availableMoods.includes(mood);
    });
  });
  availablePlanList.sort((a, b) => {
    const priceA = a.floors.find((floor) => floor.number === floorNumber);
    const priceB = b.floors.find((floor) => floor.number === floorNumber);
    return priceA.price - priceB.price;
  });
  return availablePlanList;
};

export const getAvailableMoods = (
  planList: Plan[],
  floorNumber: number,
  isFloorChosen: boolean,
): Mood[] => {
  let availablePlanList = planList;
  //controllo se il piano e' stato scelto, altrimenti non filtro
  if (isFloorChosen) {
    availablePlanList = planList.filter((plan) =>
      plan.floors.some((floor) => {
        return floor.number === floorNumber;
      }),
    );
  }

  const moodList: Mood[] = [];
  const allAvailableMoods = new Set<string>();
  availablePlanList.forEach((plan) => {
    plan.floors.forEach((floor) => {
      //if the floor is chosen before, I need to consider only floors with that particular value, otherwise I need to consider all floors
      if ((isFloorChosen && floor.number === floorNumber) || !isFloorChosen) {
        floor.availableMoods.forEach((mood) => {
          allAvailableMoods.add(mood);
        });
      }
    });
  });
  availablePlanList.forEach((plan) => {
    plan.typology.formulas.forEach((formula) => {
      const moodName = formula.name.toLowerCase();
      if (!moodList.find((mood) => mood.name === moodName)) {
        moodList.push({
          name: moodName,
          available: allAvailableMoods.has(moodName),
        });
      }
    });
  });

  moodList.sort((a, b) => a.name.length - b.name.length);
  return moodList;
};

export default {
  checkAndSetApartments,
  filterApartmentsByDates,
  getAvailableApartments,
  getIncludedCellarInfo,
  getPlanByName,
  getPlanListByType,
  getMinPrice,
  sortFeaturesByLowestPrice,
  getApartmentPrice,
  getFeaturesTotalPrice,
  getFeaturesTotalExpense,
  getApartmentData,
  getInteriorPrice,
  getPacksList,
  getMinFloor,
  togglePack,
  getTotalPacksPrice,
  getUpdatedPacksList,
  getEmptyCellarInfo,
  getPlanByMoodAndOrFloor,
  getFirstAvailableApartment,
  getAvailablePlans,
  getAvailableMoods,
};
