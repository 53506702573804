import React, { lazy, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { loadResources, useTranslation } from '@tecma/i18n';

import TokenCountdown from './components/TokenCountdown';
import Client from './client/Client';
import { useQuery } from '@apollo/react-hooks';
import { DetectUrl } from './utils/DetectUrl';
import { setProjectInfo } from './store/slices/info';
import { useAppDispatch } from './store/hooks';
import { Loader } from './components/Loader';
import { setParams } from './store/slices/rent';
import { loadScssFromDB } from './utils/loadScssFromDB';
import { ProjectInfo } from './types/info';
import { setMinMonthDifference } from './store/slices/date';

//Pages
const Layout = lazy(() => import('./pages/Layout'));
const Interceptor = lazy(() => import('./pages/Interceptor'));

const browserRouterBasename = process.env.REACT_APP_BASE_URL;

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { data, loading, called } = useQuery<{ getProjectInfoByHost: ProjectInfo }>(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()),
  );

  useEffect(() => {
    if (data && called && data.getProjectInfoByHost) {
      const project = data.getProjectInfoByHost;
      // i18n.changeLanguage(project.defaultLang);
      dispatch(setProjectInfo(project));
      loadResources({
        id: project.id,
        displayName: project.displayName,
        languages: project.floorPlanningRentConfig.languages,
      });
      if (data.getProjectInfoByHost.floorPlanningRentConfig)
        dispatch(setParams(data.getProjectInfoByHost.floorPlanningRentConfig.componentParams));
      const minRentMonths = data.getProjectInfoByHost?.floorPlanningRentConfig?.minRentMonths;
      if (minRentMonths) {
        dispatch(setMinMonthDifference({ monthDifference: minRentMonths }));
      }
      loadScssFromDB(data.getProjectInfoByHost);
    }
  }, [data, called, i18n, dispatch]);

  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      const AppRef = document.querySelector('div#rent-platform-app');
      const AuthModalRef = document.querySelector('div.am-modal-form.keyboard-open');
      if (window.innerHeight > window.innerWidth) {
        AppRef.classList.add('turn-your-phone');
      } else {
        if (window.innerHeight <= window.innerWidth && AuthModalRef) {
          AppRef.classList.add('turn-your-phone');
          AuthModalRef.classList.remove('keyboard-open');
        } else {
          AppRef.classList.remove('turn-your-phone');
        }
      }
    });
  }, []);

  return !loading ? (
    <>
      <BrowserRouter basename={browserRouterBasename}>
        <React.Suspense fallback={<Loader type="ThreeDots" />}>
          <Switch>
            <Route exact path={`/`} component={Layout} />
            <Route path={`*`} component={Interceptor} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      <TokenCountdown threshold={process.env.REACT_APP_TIMEOUT_TOKEN} />
    </>
  ) : (
    <></>
  );
};

export default App;
