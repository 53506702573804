import { cloneDeep } from 'lodash';
import { Step } from '../../types/info';

/**
 * returns new status of the step
 * @param {*} status
 */
export const getNewStepStatus = (status: string) => {
  return status === 'start' ? 'conferma' : status === 'conferma' ? 'completed' : 'conferma';
};

/**
 * returns editable steps
 * @param {*} steps
 */
export const getSteps = (steps: Step[]) => {
  const stepsNew: Step[] = [];
  steps.forEach((step) => {
    const newStep = cloneDeep(step);
    stepsNew.push(newStep);
  });
  const orderedSteps = [...stepsNew].sort((a, b) => {
    return a.index - b.index > 0 ? 1 : -1;
  });
  return orderedSteps;
};

/**
 * returns whether or not step is active
 * @param {*} currentStatus
 */
export const isStepActive = (currentStatus: string) => {
  return currentStatus === 'start' ? true : false;
};

/**
 * reset all the made choices and status for steps that go after the new current step
 * @param {*} steps
 * @param {*} currentStep
 */
export const resetStepsStatus = (steps: Step[], currentStep: number) => {
  for (let i = currentStep + 1; i <= steps.length; i++) {
    let step = steps.find(({ index }: { index: number }) => index === i);
    const stepIndex = steps.indexOf(step);
    step = JSON.parse(JSON.stringify(step));
    step.status = 'start';
    step.active = false;
    steps[stepIndex] = step;
  }
  return steps;
};

/**
 * reset all steps
 * @param {*} steps
 * @param {*} currentStep
 */
export const resetAllSteps = (steps: Step[]): Step[] => {
  const newSteps = [...steps].map((step: Step) => {
    step = cloneDeep(step);
    if (step.index === 1) {
      step.status = 'conferma';
      step.active = true;
      step.show = true;
    } else {
      step.status = 'start';
      step.active = false;
      step.show = false;
    }
    return step;
  });
  return newSteps;
};

export default {
  getSteps,
  getNewStepStatus,
  isStepActive,
  resetStepsStatus,
  resetAllSteps,
};
