import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import API from './client/GraphqlClient';
import store from './store';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { injectContextHeaders } from '@tecma/logs-fe';
import { initI18n } from '@tecma/i18n';
import translation from './i18n/translations/en/translation.json';

// styles
import './theme/global.scss';

injectContextHeaders();

initI18n(
  translation,
  {
    bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
    productName: `${process.env.REACT_APP_PRODUCT_NAME}`,
    apiBase: `${process.env.REACT_APP_API_URI}`,
  },
  'it-IT',
  process.env.REACT_APP_PSEUDO_LANG,
).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ApolloProvider client={API()}>
          <div id="rent-platform-app">
            <App />
          </div>
        </ApolloProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});
