import { gql } from '@apollo/client';
import { OtherInfo } from '../../types/contact';

export interface GetInfoByFloorPlanningInput {
  firstName: string;
  lastName: string;
  email: string;
  tel: string;
  interests?: string[];
  source?: string;
  calendarDefaultMode?: string;
  trattamento: boolean;
  profilazione: boolean;
  marketing: boolean;
  note?: string;
  city?: string;
  status?: string;
  planName: string;
  otherInfo?: OtherInfo;
}

const CONTACT_DATA = `
  firstName
  lastName
  email
  tel
  trattamento
  profilazione
  marketing
  note
  city
  status
`;

export const CONTACT_MUTATION = gql`
  mutation getInfoByFloorPlanning($project_id :ID!, $getInfoByFloorPlanningInput: GetInfoByFloorPlanningInput!) {
    getInfoByFloorPlanning(project_id: $project_id, input: $getInfoByFloorPlanningInput) {
          ${CONTACT_DATA}
          }
      }
`;

export interface ContactOptions {
  variables: {
    project_id: string;
    getInfoByFloorPlanningInput: GetInfoByFloorPlanningInput | null;
  };
  fetchPolicy: 'no-cache';
}

export const CONTACT_OPTIONS = (
  project_id: string,
  getInfoByFloorPlanningInput: GetInfoByFloorPlanningInput | null,
) => {
  const options: ContactOptions = {
    variables: {
      project_id,
      getInfoByFloorPlanningInput,
    },
    fetchPolicy: 'no-cache',
  };
  return options;
};
