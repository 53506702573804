import React, { Fragment, useState } from 'react';
import { Trans, useTranslation } from '@tecma/i18n';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setType } from '../../store/slices/rent';
import { useWindowSize } from '../../hooks';
import StepOptionHeader from '../common/StepOptionHeader';
import AnimatedSpacePicker from './AnimatedSpacePicker';
import { getTranslationInfo, getLabel, getMonthlyPrice } from '../../utils/utils';
import NotificationBox from '../common/NotificationBox';
import { Icon } from '../Icon';

interface SpacePickerProps {
  stepName: string;
}

const SpacePicker: React.FC<SpacePickerProps> = ({ stepName }) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const type = useAppSelector((state) => state.rent.type);
  const rentInformation = useAppSelector((state) => state.rent);
  const steps = useAppSelector((state) => state.info.steps);
  const isTotalRateWithoutExpenses = useAppSelector(
    (state) => state.info.isTotalRateWithoutExpenses,
  );
  const defaultTypes = useAppSelector((state) => state.rent.defaultTypes);
  const availability = useAppSelector((state) => state.rent.availability);
  const complex = useAppSelector((state) => state.rent.complex);
  const step = steps.find((stepItem) => stepItem.name === stepName);
  const typeOptions = Object.keys(defaultTypes);
  const [index, setIndex] = useState(() => {
    if (type === '') {
      return -1;
    } else {
      return typeOptions.findIndex((option) => option === type);
    }
  });
  const dates = useAppSelector((state) => state.dates);

  const getOption = (optionIndex: number) => {
    if (optionIndex === -1)
      return t('configurator.choose-models', {
        count: typeOptions.length,
      });
    return t(`step.space.options.${getLabel(typeOptions[optionIndex])}`);
  };

  const updateIndex = (direction: string) => {
    let ind = 0;
    if (direction === 'left') {
      index <= 0 ? (ind = typeOptions.length - 1) : (ind = index - 1);
    } else {
      index === typeOptions.length - 1 ? (ind = 0) : (ind = index + 1);
    }
    setIndex(ind);
    const typeSelected = typeOptions[ind];
    dispatch(setType({ type: typeSelected, dates }));
  };

  const getDescTranslateValue = (indexItem: number) => {
    if (width < 375) {
      if (indexItem === 0) {
        return '-66%';
      }
      return indexItem === 1 ? '-90%' : -(indexItem * 100 - 10) + '%';
    }
    if (width < 420) {
      return indexItem === 0 ? '-60%' : -indexItem * 100 + '%';
    }
    if (width < 850) {
      return indexItem === 0 ? '-50%' : -indexItem * 100 + '%';
    }
    return indexItem === 0 ? '-60%' : -indexItem * 100 + '%';
  };

  const getI18nKey = (optionType: string) => {
    if (complex) {
      return `configurator.${complex}.type-included.${getLabel(optionType)}`;
    } else {
      return `configurator.type-included.${getLabel(optionType)}`;
    }
  };

  const label = getTranslationInfo(availability, 'configurator').label;
  const options = getTranslationInfo(availability, 'configurator').options || {};

  return (
    <div className="option-container">
      <StepOptionHeader headerName={step.name} />
      <NotificationBox show={true} text={t(label, options)} classCss="sold" />
      <div className="space-selection-container">
        <div className="space-selector-box">
          <div className="space-selector">
            <div className="arrow-select left">
              <div
                className="arrow-btn-select left"
                onClick={() => {
                  updateIndex('left');
                }}
              >
                <Icon name="arrow" />
              </div>
            </div>
            <AnimatedSpacePicker
              item={getOption(index)}
              order={index}
              maxOrder={typeOptions.length - 1}
            />
            <div className="arrow-select right">
              <div
                className="arrow-btn-select right"
                onClick={() => {
                  updateIndex('right');
                }}
              >
                <Icon name="arrow" />
              </div>
            </div>
          </div>
        </div>
        <div className="space-description-container">
          <div className={`space-descr ${index === 0 ? `init` : `${index === -1 ? `` : `hide`}`}`}>
            <div className="init-space-descr-text">
              {t('configurator.number-rooms')}
              <br />
              {t('configurator.from-currency', { currency: '€' })}
            </div>
          </div>
          {typeOptions.map((option, key) => (
            <div
              key={key}
              style={{ transform: `translateY(${getDescTranslateValue(index)})` }}
              className={`space-descr desc-${index}`}
            >
              <div className="space-descr-text">
                <Fragment>
                  <Trans i18nKey={getI18nKey(option)} components={{ 1: <br /> }} />
                </Fragment>
              </div>
              <div className="space-price">
                {t('configurator.from-price', {
                  price: getMonthlyPrice(isTotalRateWithoutExpenses, rentInformation),
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SpacePicker;
