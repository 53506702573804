import Cookies from 'js-cookie';
import GetDomain from '../../utils/GetDomain';

export const generateCookiesOptions = (): any => {
  return {
    expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
    domain: GetDomain(),
  };
};

export const IMPERSONATED_CLIENT_COOKIE = 'impersonatedClient';
export const JWT_RENT_COOKIE = 'jwtRent';
export const REFRESH_TOKEN_RENT_COOKIE = 'refreshTokenRent';
export const EXPIRES_IN_RENT_COOKIE = 'expiresInRent';

export const isImpersonaCookieUserSet = (): boolean => !!Cookies.get(IMPERSONATED_CLIENT_COOKIE);

export const getImpersonaCookie = (): string => Cookies.get(IMPERSONATED_CLIENT_COOKIE);
export const resetImpersonaCookie = (): void => {
  Cookies.remove(IMPERSONATED_CLIENT_COOKIE, generateCookiesOptions());
};
