import { useEffect, useState } from 'react';
import { animated, useTransition, UseTransitionProps } from 'react-spring';
import { AnimatedSpacePickerValues, PickerCss } from '../../types/common';
import './pickers.scss';

interface AnimatedPickerProps {
  item: string;
  order: number;
  maxOrder: number;
}

const AnimatedSpacePicker: React.FC<AnimatedPickerProps> = ({ item, order, maxOrder }) => {
  const [values, setValues] = useState<AnimatedSpacePickerValues[]>([
    { value: item, key: order },
    { value: item, key: order },
  ]);
  const lastValue = values[values.length - 1];

  useEffect(() => {
    if (item !== lastValue.value) {
      setValues([lastValue, { value: item, key: order }]);
    }
  }, [item, order, lastValue]);

  const getCss = () => {
    let css: PickerCss = null;
    if (values.length > 1) {
      if (values[0].key <= values[1].key) {
        css = {
          from: { transform: 'translateY(30px)', opacity: 0 },
          enter: { transform: 'translateY(0)', opacity: 1 },
          leave: { transform: 'translateY(-30px)', opacity: 0 },
        };
      }
      if (values[0].key >= values[1].key && values[0].key !== -1) {
        css = {
          from: { transform: 'translateY(-30px)', opacity: 0 },
          enter: { transform: 'translateY(0)', opacity: 1 },
          leave: { transform: 'translateY(30px,)', opacity: 0 },
        };
      }
      if (values[0].key === maxOrder && values[1].key < 1) {
        css = {
          from: { transform: 'translateY(30px)', opacity: 0 },
          enter: { transform: 'translateY(0)', opacity: 1 },
          leave: { transform: 'translateY(-30px,)', opacity: 0 },
        };
      }
      if (values[1].key === maxOrder && values[0].key < 1) {
        css = {
          from: { transform: 'translateY(-30px)', opacity: 0 },
          enter: { transform: 'translateY(0)', opacity: 1 },
          leave: { transform: 'translateY(30px,)', opacity: 0 },
        };
      }
    }
    return css;
  };

  const cssProps = getCss();

  const transition = useTransition<
    AnimatedSpacePickerValues,
    UseTransitionProps<AnimatedSpacePickerValues>
  >(lastValue, {
    key: (itemValue: any) => itemValue.key,
    ...lastValue,
    ...cssProps,
  });

  return (
    <div className={`option ${order < 0 ? 'disabled' : ''}`}>
      <div className="option-label">
        {transition((render, pickerItem) => {
          const { transform, opacity } = render;
          return (
            <animated.div
              className={`option-label-text${order < 0 ? '-select' : ''}`}
              style={{ opacity, transform }}
              key={pickerItem.key}
            >
              {pickerItem.value}
            </animated.div>
          );
        })}
      </div>
    </div>
  );
};

export default AnimatedSpacePicker;
