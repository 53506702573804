import { gql } from '@apollo/client';

const PROJECT_DATA_TO_RETRIEVE = `
  id
  name
  logo
  locale
  defaultLang
  assetLogin
  hostKey
  assetKey
  assetsUrl
  displayName
  pageTitles {
      floorPlanning
  }
  payoff
  manifestConfig
  floorPlanningRentConfig
  rentAssetContext {
    daysToBeReady
    monthsToExclude {
      month
      year
    }
    isTotalRateWithoutExpenses
  }
  style
`;

export const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host :String!) {
    getProjectInfoByHost (host :$host) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }
`;

export const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname: string) => {
  return {
    variables: {
      host: hostname,
    },
  };
};
