import { useEffect, useState } from 'react';
import { useTranslation } from '@tecma/i18n';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setFloor } from '../../store/slices/rent';
import * as util from '../../utils/utils';
import StepOptionHeader from '../common/StepOptionHeader';
import NotificationBox from '../common/NotificationBox';

import './pickers.scss';

interface FloorPickerProps {
  stepName: string;
}

const FloorPicker: React.FC<FloorPickerProps> = ({ stepName }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const planList = useAppSelector((state) => state.rent.planList);
  const apartmentInfo = useAppSelector((state) => state.rent.apartmentInfo);
  const floor = useAppSelector((state) => state.rent.floor);
  const mood = useAppSelector((state) => state.rent.mood);
  const dates = useAppSelector((state) => state.dates);
  const steps = useAppSelector((state) => state.info.steps);
  const step = steps.find((stepItem) => stepItem.name === stepName);
  const totalFloors = apartmentInfo.totalFloors;

  const isMoodChosen =
    steps.length && steps.find((st) => st.name === 'mood')?.status === 'completed';

  const [availableFloors, setAvailableFloors] = useState(
    util.getAvailableFloors(planList, isMoodChosen, mood),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getIndex = (floors: number[]) => {
    return floors.indexOf(floor.number);
  };

  const [index, setIndex] = useState(getIndex(availableFloors));

  useEffect(() => {
    const newAvailableFloors = util.getAvailableFloors(planList, isMoodChosen, mood);
    setAvailableFloors(newAvailableFloors);
    setIndex(getIndex(newAvailableFloors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planList]);

  const dispatchSetFloor = (floorNumber: number) => {
    dispatch(setFloor({ floorNumber: availableFloors[floorNumber], dates, isMoodChosen }));
  };

  const increaseFloorNumber = (value: number) => {
    if (index < availableFloors.length - 1) {
      const nextIndex = value + 1;
      setIndex(nextIndex);
      dispatchSetFloor(nextIndex);
    }
  };
  const decreaseFloorNumber = (value: number) => {
    if (index > 0) {
      const previousIndex = value - 1;
      setIndex(previousIndex);
      dispatchSetFloor(previousIndex);
    }
  };
  const isDisabled = (sign: string) => {
    return availableFloors.length === 1 ||
      (sign === 'minus' && index === 0) ||
      (sign === 'plus' && index === availableFloors.length - 1)
      ? true
      : false;
  };

  return (
    <div className="option-container">
      <StepOptionHeader headerName={step.name} />
      <div className="floor-picker-container">
        <div className="floor-picker-box">
          <div className={`arrow-select ${isDisabled('minus') ? `disable` : ``}`}>
            <div
              className="arrow-btn-select plus-minus"
              onClick={() => {
                decreaseFloorNumber(index);
              }}
            >
              <div>-</div>
            </div>
          </div>
          <div className="option picker">
            <div className="option-label picker"> {availableFloors[index]}° </div>
          </div>
          <div className={`arrow-select ${isDisabled('plus') ? `disable` : ``}`}>
            <div
              className="arrow-btn-select plus-minus"
              onClick={() => {
                increaseFloorNumber(index);
              }}
            >
              <div>+</div>
            </div>
          </div>
        </div>
        <div className="floor-number-total">
          {totalFloors && t('configurator.of-floors', { floors: totalFloors })}
        </div>
      </div>
      <NotificationBox
        show={availableFloors.length === 1}
        text={t('configurator.floor-available', { floor: availableFloors[0] })}
        classCss="floor"
      ></NotificationBox>
    </div>
  );
};

export default FloorPicker;
