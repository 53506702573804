import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import Cookies from 'js-cookie';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_APOLLO_URI });
const API = () => {
  const getToken = (): string => {
    const jwt = Cookies.get('jwtRent');
    const token = process.env.REACT_APP_JWT_TOKEN;
    return jwt ? `Bearer ${jwt}` : token;
  };

  const authLink = new ApolloLink((operation, forward) => {
    const omitTypename = (key: string, value: string) => (key === '__typename' ? undefined : value);

    if (operation.variables && !operation.getContext().hasUpload) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    operation.setContext({
      headers: {
        authorization: getToken(),
      },
    });
    return forward(operation);
  });

  return new ApolloClient<NormalizedCacheObject>({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export default API;
