import { gql } from '@apollo/client';

export interface ModelInput {
  name: string;
  active?: boolean;
  desk_visibility?: boolean;
  web_visibility?: boolean;
  temp_desk_visibility?: boolean;
  temp_web_visibility?: boolean;
  visits?: number;
}

const MODEL_DATA_TO_RETRIEVE = `
    id
    name
    desk_visibility
    web_visibility
    temp_web_visibility
    temp_desk_visibility
    active
    count
`;

export const UPDATE_MODEL_MUTATION = gql`
  mutation updateModels($project_id :ID!, $modelId: ID!, $modelInput: ModelInput!) {
    updateModels(project_id: $project_id, id: $modelId, input: $modelInput) {
          ${MODEL_DATA_TO_RETRIEVE}
          }
      }
`;

export const UPDATE_MODEL_OPTIONS = (
  project_id: string,
  modelId: string,
  modelInput: ModelInput,
) => {
  return {
    variables: {
      project_id,
      modelId,
      modelInput,
    },
    fetchPolicy: 'no-cache',
  };
};
