import { gql } from '@apollo/client';

export interface PlanInput {
  name: string;
  code?: string;
  typology: string;
  model: string;
  dimension?: string;
  image?: string;
  web_visibility?: boolean;
  desk_visibility?: boolean;
  SuperficieGiardino?: string;
  SuperficieTerrazzo?: string;
  SuperficieAppartamento?: string;
  SuperficieLoggia?: string;
  SuperficieCommerciale?: string;
  SuperficieAttico?: string;
  SuperficieTotale?: string;
  bathroom?: number;
  bedroom?: number;
  visits?: number;
  extraInfo?: JSON;
  quadrants?: string[];
}

const PLANS_DATA_TO_RETRIEVE = `
    id
    name
    count
    image
    desk_visibility
    web_visibility
    SuperficieGiardino
    SuperficieTotale
    SuperficieTerrazzo
    SuperficieAppartamento
    SuperficieLoggia
    count
    minPrice
    floors
    model {
    id
    name
    }
    typology{
    id
    name
    }
    extraInfo
`;

export const UPDATE_PLAN_MUTATION = gql`
  mutation updatePlans($project_id :ID!, $planId: ID!, $planInput: PlanInput!) {
    updatePlans(project_id: $project_id, id: $planId, input: $planInput) {
          ${PLANS_DATA_TO_RETRIEVE}
          }
      }
`;

export const UPDATE_PLAN_OPTIONS = (project_id: string, planId: string, planInput: PlanInput) => {
  return {
    variables: {
      project_id,
      planId,
      planInput,
    },
    fetchPolicy: 'no-cache',
  };
};
