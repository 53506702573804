import React from 'react';
import { Icon } from '../Icon';
import './common.scss';

interface NotificationBoxProps {
  show: boolean;
  text: string;
  classCss: string;
}

const NotificationBox: React.FC<NotificationBoxProps> = ({ show, text, classCss }) => {
  return (
    <div className={`notice-box-container ${classCss}`}>
      <div className={`notice-box ${classCss}`} style={{ display: `${show ? `flex` : `none`}` }}>
        <Icon name="notification" className={`notice-box-icon ${classCss}`} />
        <div className={`notice-box-text ${classCss}`}>{text}</div>
      </div>
    </div>
  );
};

export default React.memo(NotificationBox);
