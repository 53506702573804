import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Scrollbars } from 'react-custom-scrollbars';
import { Trans, useTranslation } from '@tecma/i18n';

import StepOptionHeader from '../common/StepOptionHeader';
import { currencyFormatter, getPackLabel, getSelectedPacks, isMobile } from '../../utils/utils';
import { setShowPickerModal } from '../../store/slices/info';
import './optional-summary.scss';

interface OptionalSummaryProps {
  stepName: string;
}

const OptionalSummary: React.FC<OptionalSummaryProps> = ({ stepName }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const complex = useAppSelector((state) => state.rent.complex);
  const packsList = useAppSelector((state) => state.rent.packsList);
  const steps = useAppSelector((state) => state.info.steps);
  const step = steps.find((stepItem) => stepItem.name === stepName);
  const selectedPacks = getSelectedPacks(packsList);
  const projectDisplayName = useAppSelector((state) => state.info.projectDisplayName);
  const packIcon = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
    projectDisplayName,
  )}/floorplanning-rent/img/icons/heart.svg`;

  return (
    <div className="option-container no-padding">
      <Scrollbars>
        <StepOptionHeader headerName={step.name} />
        {isMobile() && (
          <div className="show-modal-picker" onClick={() => dispatch(setShowPickerModal(true))}>
            <div
              style={{ backgroundImage: `url(${packIcon})` }}
              className="show-modal-picker-icon"
            ></div>
            <div className="show-modal-divider"></div>
            <div className="show-modal-label">{t('configurator.show-pack')}</div>
          </div>
        )}
        {selectedPacks.length === 0 ? (
          <div className="active-packs-list">
            <div className="active-packs-text">
              <Trans i18nKey="configurator.no-pack-added" components={{ 1: <strong /> }} />
            </div>
            <div className="box-month-rate">
              <div>
                <Trans
                  i18nKey="configurator.pack-price-zero"
                  components={{ 1: <span className="grey-text" /> }}
                  values={{ currency: '€' }}
                />
              </div>
            </div>
          </div>
        ) : (
          selectedPacks.map((selectedPack, key) => (
            <div className="active-packs-list" key={key}>
              <div className="active-packs-text">
                <strong>{t(getPackLabel(selectedPack.name, complex))}</strong>
                &nbsp;{t('configurator.pack')}
              </div>
              <div className="box-month-rate">
                {selectedPack.alreadyIncluded ? (
                  <div>{t('configurator.included')}</div>
                ) : (
                  <div>
                    <Trans
                      i18nKey="configurator.pack-price"
                      components={{ 1: <span className="grey-text" /> }}
                      values={{ price: currencyFormatter.format(Math.round(selectedPack.price)) }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </Scrollbars>
    </div>
  );
};

export default OptionalSummary;
