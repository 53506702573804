export const getNormalizedUrl = (): string =>
  window.location.hostname.indexOf('www.') >= 0
    ? window.location.hostname
    : 'www.' + window.location.hostname;

export const getToolUrl = (tool: string): string =>
  `${window.location.protocol}//${window.location.hostname.replace(
    'floorplanning-rent',
    tool,
  )}/?hostname=${localStorage.getItem('floorplanning_rent_hostname')}&&`;

export const getMyHomeUrl = (): string =>
  `${getToolUrl('myhome-rent')}returnUrl=${window.location.hostname}`;

export const DetectUrl = (): string => {
  let hostname = getNormalizedUrl();

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get('hostname');

  if (
    hostname.indexOf('localhost') >= 0 ||
    hostname.indexOf('.ddns.net') >= 0 ||
    hostname.indexOf('biz-tecma') >= 0 ||
    hostname.indexOf('-demo.tecmasolutions.com') >= 0
  ) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== '') {
      localStorage.removeItem('floorplanning_rent_hostname');
      delete localStorage['floorplanning_rent_hostname'];
      localStorage.setItem('floorplanning_rent_hostname', hostnameParam);
    }

    const floorplanning_rent_hostname = localStorage.getItem('floorplanning_rent_hostname');

    if (
      floorplanning_rent_hostname &&
      floorplanning_rent_hostname.length > 0 &&
      floorplanning_rent_hostname !== ''
    ) {
      return floorplanning_rent_hostname;
    }

    hostname = process.env.REACT_APP_HOST;
  }

  return hostname;
};


