import React from 'react';
import Loading from 'react-loader-spinner';
import variables from '../../theme/utils/variables.module.scss';
import { LoaderType } from '../../types/common';

// styles
import './loader.scss';

const Loader = ({
  type,
  cssClass = '',
  height = 100,
  width = 100,
}: {
  type: keyof typeof LoaderType;
  noMargin?: boolean;
  cssClass?: string;
  height?: number;
  width?: number;
}) => {
  return (
    <div className={`rent-loader ${cssClass}`}>
      <Loading type={type} color={variables.accent} height={height} width={width} />
    </div>
  );
};

export default React.memo(Loader);
