import React from 'react';
import { useTranslation } from '@tecma/i18n';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setMood } from '../../../store/slices/rent';
import StepOptionHeader from '../../common/StepOptionHeader';
import { getAvailableMoods } from '../../../store/functions/rentFunctions';
import { getMoodNameTranslated } from '../../../utils/utils';

//style
import './mood-picker.scss';

interface MoodPickerProps {
  stepName: string;
}

const MoodPicker: React.FC<MoodPickerProps> = ({ stepName }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const planList = useAppSelector((state) => state.rent.planList);
  const floor = useAppSelector((state) => state.rent.floor);
  const mood = useAppSelector((state) => state.rent.mood);
  const dates = useAppSelector((state) => state.dates);
  const steps = useAppSelector((state) => state.info.steps);
  const moodColors = useAppSelector((state) => state.info.moodColors);
  const building = useAppSelector((state) => state.rent.apartmentInfo.building);

  const step = steps.find((stepItem) => stepItem.name === stepName);
  const isFloorChosen =
    steps.length && steps.find((st) => st.name === 'floor')?.status === 'completed';
  const moodList = getAvailableMoods(planList, floor.number, isFloorChosen);

  return (
    <div className="option-container no-padding">
      <StepOptionHeader headerName={step.name} />
      <div className="moodpicker-container">
        {moodList.map((moodItem, key) => {
          const moodContainerClass = classNames('mood-container', {
            'active-mood': moodItem.name === mood && moodItem.available,
            'disabled-mood': !moodItem.available,
          });
          return (
            <div
              key={key}
              className={moodContainerClass}
              onClick={() => {
                if (moodItem.available) {
                  dispatch(setMood({ mood: moodItem.name, dates, isFloorChosen }));
                }
              }}
            >
              <div className="mood-circle-container">
                <div
                  className={`mood-circle ${
                    moodItem.name === mood && moodItem.available ? `active-mood` : ``
                  }`}
                  style={
                    moodColors[moodItem.name] && moodItem.available
                      ? {
                          backgroundColor: moodColors[moodItem.name],
                        }
                      : {}
                  }
                />
              </div>
              <div
                className={`mood-container-text ${
                  moodItem.name === mood && moodItem.available ? `active-mood` : ``
                }`}
              >
                {t(getMoodNameTranslated(moodItem.name, building))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(MoodPicker);
