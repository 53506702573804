import { cloneDeep } from 'lodash';
import { DateTime, DateTimeJSOptions, DurationObjectUnits } from 'luxon';

const LOCALE = 'it-IT';
const ZONE = 'Europe/Rome';

const getTimeFragments = (
  date: DateTime,
): [number, number, number, number, number, number, number, DateTimeJSOptions] => [
  date.year,
  date.month,
  date.day,
  date.hour,
  date.minute,
  date.second,
  date.millisecond,
  {
    zone: ZONE,
    locale: LOCALE,
  },
];

export interface DateObject {
  timestamp: number;
  date: DateTime;
  day: number;
  previousMonth: {
    timestamp: number;
    value: string;
  };
  month: { value: string };
  nextMonth: { timestamp: number; value: string };
  previousYear: { timestamp: number; value: string };
  year: {
    value: string;
  };
  nextYear: { timestamp: number; value: string };
}

export const getStartOrEndMonth = (date: DateTime, isEnd: boolean) => {
  const localeDate = DateTime.local(...getTimeFragments(date));
  return isEnd ? localeDate.endOf('month') : localeDate.startOf('month');
};

export const setDayAndMonth = (date: DateTime, month: number, day: number) => {
  if (month === 0) {
    month = 12;
    date = date.minus({ years: 1 });
  }
  if (month === 13) {
    month = 1;
    date = date.plus({ years: 1 });
  }
  if (day === 0 || day === null || day === undefined) {
    return DateTime.local(
      date.year,
      month,
      1,
      date.hour,
      date.minute,
      date.second,
      date.millisecond,
    ).endOf('month');
  } else {
    return date.set({ month: month, day: day });
  }
};

/**
 * returns a day value
 * @param {*} date a date object
 */
export const getDay = (date: Date) => date.getUTCDate();

export const getNextDay = (date: DateTime, days: number, lastDayOfMonth = false) => {
  const nextDay = lastDayOfMonth ? getStartOrEndMonth(date, true) : getStartOrEndMonth(date, false);
  return nextDay.set({ day: days });
};

export const getPreviousDay = (date: DateTime, days: number, lastDayOfMonth = false) => {
  const nextDay = lastDayOfMonth ? getStartOrEndMonth(date, true) : getStartOrEndMonth(date, false);
  return nextDay.minus({ days: days });
};

/**
 * returns the date object of the next month
 * @param {*} date
 * @param {*} monthsToAdd
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 */
export const getNextMonth = (date: DateTime, monthsToAdd = 1, lastDayOfMonth: boolean) => {
  const nextMonth = date.plus({ months: monthsToAdd });
  return lastDayOfMonth
    ? getStartOrEndMonth(nextMonth, true)
    : getStartOrEndMonth(nextMonth, false);
};

/**
 * returns the date object of the previous month
 * @param {*} date
 * @param {*} monthsToAdd
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 */
export const getPreviousMonth = (date: DateTime, monthsToRemove = 1, lastDayOfMonth: boolean) => {
  const previousMonth = date.minus({ months: monthsToRemove });
  return lastDayOfMonth
    ? getStartOrEndMonth(previousMonth, true)
    : getStartOrEndMonth(previousMonth, false);
};
/**
 * returns the date object of next year
 * @param {*} date
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 * @param {*} keepMonth if true keeps the month as the input otherwise one month before
 */
export const getNextYear = (date: DateTime, lastDayOfMonth = true, keepMonth?: boolean) => {
  let nextYear = lastDayOfMonth ? getStartOrEndMonth(date, true) : getStartOrEndMonth(date, false);
  nextYear = nextYear.plus({ years: 1 });
  return keepMonth ? nextYear : nextYear.plus({ months: 1 });
};

export const getNextYearEndDate = (date: DateTime) => {
  let nextYear = getStartOrEndMonth(date, true);
  nextYear = nextYear.plus({ years: 1 });
  return nextYear.minus({ months: 1 });
};

export const getNextYearWithDay = (date: DateTime) => {
  return date.plus({ years: 1 });
};

/**
 * returns the date object of previous year
 * @param {*} date
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 * @param {*} keepMonth if true keeps the month as the input otherwise one month before
 */
export const getPreviousYear = (date: DateTime, lastDayOfMonth = true, keepMonth?: boolean) => {
  let previousYear = lastDayOfMonth
    ? getStartOrEndMonth(date, true)
    : getStartOrEndMonth(date, false);
  previousYear = previousYear.minus({ years: 1 });
  return keepMonth ? previousYear : getStartOrEndMonth(previousYear, true);
};

export const getPreviousYearWithDay = (date: DateTime) => {
  return date.minus({ years: 1 });
};
/**
 * returns the date object
 * @param {*} date
 * @param {*} lastDayOfMonth if true gives you the last day of the
 * month otherwise the first ; default is true
 */
export const getDateObject = (date: DateTime, lastDayOfMonth = true): DateObject => {
  const nextMonth = getNextMonth(date, 1, lastDayOfMonth);
  const previousMonth = getPreviousMonth(date, 1, lastDayOfMonth);
  const nextYear = getNextYear(date, lastDayOfMonth, false);
  const previousYear = getPreviousYear(date, lastDayOfMonth, true);
  return {
    timestamp: date.toMillis(),
    date,
    day: date.day,
    previousMonth: {
      timestamp: previousMonth.toMillis(),
      value: DateTime.local(...getTimeFragments(previousMonth)).toLocaleString({ month: 'long' }),
    },
    month: {
      value: DateTime.local(...getTimeFragments(date)).toLocaleString({ month: 'long' }),
    },
    nextMonth: {
      timestamp: nextMonth.toMillis(),
      value: DateTime.local(...getTimeFragments(nextMonth)).toLocaleString({ month: 'long' }),
    },
    previousYear: {
      timestamp: previousYear.toMillis(),
      value: DateTime.local(...getTimeFragments(getPreviousYear(date))).toLocaleString({
        year: 'numeric',
      }),
    },
    year: {
      value: DateTime.local(...getTimeFragments(date)).toLocaleString({ year: 'numeric' }),
    },
    nextYear: {
      timestamp: nextYear.toMillis(),
      value: DateTime.local(...getTimeFragments(getNextYear(date))).toLocaleString({
        year: 'numeric',
      }),
    },
  };
};

/**
 * returns the year different between two date objects
 * @param {*} start the starting date object
 * @param {*} finish the ending date object
 */
export const getYearsDiff = (start: DateTime, finish: DateTime) => {
  return finish.year - start.year;
};
/**
 * returns the  month different between two date objects
 * @param {*} start the starting date object
 * @param {*} finish the ending date object
 */
export const getMonthsDiff = (start: DateTime, finish: DateTime) => {
  const years = getYearsDiff(start, finish);
  const monthsDiff = finish.month - start.month;
  return years * 12 + monthsDiff;
};

/**
 *  returns a boolean value if the difference between
 *  the begining and end date is valid
 * @param {*} start start date object
 * @param {*} finish end date object
 * @param {*} VALID_MONTHS_DIFFERENCE the month inteval difference
 */
export const isValidEnd = (start: DateTime, finish: DateTime, VALID_MONTHS_DIFFERENCE = 10) => {
  return getMonthsDiff(start, finish) >= VALID_MONTHS_DIFFERENCE;
};

export const isValidEndWithMidMonth = (
  start: DateTime,
  finish: number,
  finishDay: number,
  VALID_MONTHS_DIFFERENCE = 11,
) => {
  let finishDate = DateTime.fromMillis(finish);
  if (finishDay != (31 || 30 || 29 || 28 || 27)) finishDate = finishDate.set({ day: finishDay });
  if (getMonthsDiff(start, finishDate) > VALID_MONTHS_DIFFERENCE) {
    return true;
  } else if (getMonthsDiff(start, finishDate) === VALID_MONTHS_DIFFERENCE) {
    if (start.day === 15 && finishDate.day === 14) {
      return false;
    } else if (
      start.day === 1 &&
      (finishDate.day === 31 ||
        finishDate.day === 30 ||
        finishDate.day === 29 ||
        finishDate.day === 28 ||
        finishDate.day === 27 ||
        finishDate.day === 1)
    ) {
      return true;
    } else return false;
  } else return false;
};
/**
 *  returns the initialMonth
 */
export const calculateInitialDate = () => {
  const now = DateTime.now();
  return now.day < 11 ? 2 : 3;
};

/**
 *  returns a boolean value if starting day is not in the past
 * @param {*} start start date object
 */

export const isValidStart = (
  start: DateTime,
  // MONTHS_DIFFERENCE: number = calculateInitialDate(),
) => {
  const now = DateTime.now();
  // return start > now && getMonthsDiff(now, start) >= MONTHS_DIFFERENCE;
  return start > now;
};

export const isValidStartDay = (start: DateTime): boolean => {
  const now = DateTime.now();
  let startDate = cloneDeep(start);
  if (start.day === 15) {
    startDate = startDate.set({ day: 1 });
  } else {
    startDate = startDate.minus({ months: 1 });
    startDate = startDate.set({ day: 15 });
  }
  return startDate > now;
};

export const getFirstValidDate = (): DateTime => {
  let firstValidDate = DateTime.now();
  if (firstValidDate.day < 15) {
    firstValidDate = firstValidDate.set({ month: firstValidDate.month, day: 15 });
  } else {
    firstValidDate = firstValidDate.set({ month: firstValidDate.month, day: 1 });
    firstValidDate = firstValidDate.plus({ months: 1 });
  }
  return firstValidDate;
};

export const getFirstValidEndDay = (date: DateTime): number => {
  if (date.day > 14) {
    return date.daysInMonth;
  } else {
    return 14;
  }
};

export const getValidEndDate = (
  startDay: DateTime,
  monthDifference = 12,
  period?: DurationObjectUnits,
): DateTime => {
  let end = cloneDeep(startDay);
  if (period) {
    end = startDay.plus(period);
    if (end.day !== 14 && end.daysInMonth !== end.day) {
      end = end.set({ day: getFirstValidEndDay(end) });
    }
  } else {
    end = startDay.plus({ month: monthDifference }).minus({ days: 1 });
  }
  return end;
};

export const endDate = (startDateArg: DateTime): DateTime => {
  let startDateValue = startDateArg;
  if (startDateValue.day === 15) {
    startDateValue = startDateValue.set({ month: startDateValue.month, day: 14 });
    return startDateValue.plus({ months: 11 });
  } else {
    startDateValue = startDateValue.set({ month: startDateValue.month, day: 1 });
    startDateValue = startDateValue.plus({ months: 11 });
    return getStartOrEndMonth(startDateValue, true);
  }
};

export default {
  getNextMonth,
  getNextYear,
  getDay,
  getDateObject,
  getPreviousMonth,
  getPreviousYear,
  getYearsDiff,
  isValidEnd,
  isValidStart,
  calculateInitialDate,
  getNextYearWithDay,
  getStartOrEndMonth,
  getPreviousYearWithDay,
  endDate,
  getFirstValidDate,
  getFirstValidEndDay,
  getValidEndDate,
};
