import { ApartmentPublic, Movement } from '../../types/rent';
import { DateTime } from 'luxon';
import { DateState } from '../slices/date';
import { cloneDeep } from 'lodash';
import { MonthsToExclude } from '../../types/info';
import { Status } from '../../constants/apartment';

export default class ApartmentPublicService {
  /**
   * Add the necessary amount of days to get ready an apartment (75 days by default)
   * @returns date after apartment preparation
   */
  getExactDateWhenApartmentIsReady = (daysToBeReady: number): DateTime => {
    return DateTime.now().plus({ days: daysToBeReady });
  };
  /**
   * Logic for deciding if it's going to be the first of the month or the 15th
   * @param date
   * @returns
   */
  getRoundedDate = (date: DateTime): DateTime => {
    if (date.day > 15) {
      date = date.set({ day: 1 }).plus({ months: 1 });
    } else {
      date = date.set({ day: 15 });
    }
    return date;
  };
  /**
   * Check if the months excluded are in between the preparation months
   * @param monthsExcluded
   * @param firstAvailableDate
   * @returns true if the months excluded are in between the preparation months
   */
  isMonthToExcludeInApartmentPreparationPeriod = (
    monthsExcluded: { month: number; year: number },
    firstAvailableDate: DateTime,
  ): boolean => {
    const now = DateTime.now();
    return (
      monthsExcluded.month < firstAvailableDate.month &&
      monthsExcluded.month >= now.month &&
      monthsExcluded.year === firstAvailableDate.year
    );
  };
  /**
   *
   * @param firstAvailableDate theoretical date when apartment is ready for rent
   * @param monthsToExclude
   * @returns date when an apartment is ready considering month to exclude
   */
  getAvailableDateAfterMonthsExcluded = (
    firstAvailableDate: DateTime,
    monthsToExclude: Array<{ month: number; year: number }>,
  ): DateTime => {
    const validMonthToExclude = monthsToExclude.filter((e) => {
      return e.month !== firstAvailableDate.month;
    });
    if (validMonthToExclude) {
      validMonthToExclude.forEach((period) => {
        if (this.isMonthToExcludeInApartmentPreparationPeriod(period, firstAvailableDate)) {
          firstAvailableDate = firstAvailableDate.plus({ month: 1 });
        }
      });
    }
    return firstAvailableDate;
  };
  getFirstValidDate = (): DateTime => {
    let firstValidDate = DateTime.now();
    if (firstValidDate.day < 15) {
      firstValidDate = firstValidDate.set({ month: firstValidDate.month, day: 15 });
    } else {
      firstValidDate = firstValidDate.set({ month: firstValidDate.month, day: 1 });
      firstValidDate = firstValidDate.plus({ months: 1 });
    }
    return firstValidDate;
  };

  getFirstValidFreeDate = (apartment: ApartmentPublic, startDate: DateTime): DateTime => {
    let date = DateTime.local();
    let validMovements = apartment.movements.filter((mv) => this.isUnavailableMovement(mv));
    if (validMovements.length) {
      validMovements = validMovements.filter((mv) => mv.checkOut && mv.checkOut);
      if (validMovements.length === 0) {
        //ho qualche movement con lo stato corretto pero' senza le date di checkIn e checkout,
        // allora non devo considerare quei appartamenti come disponibili -> restituisco una data troppo lontana
        return date.plus({ years: 8 });
      }
    }
    validMovements.sort((mov1, mov2) => {
      if (DateTime.fromISO(mov1.checkIn) < DateTime.fromISO(mov2.checkIn)) return -1;
      else if (DateTime.fromISO(mov1.checkIn) > DateTime.fromISO(mov2.checkIn)) return 1;
      else return 0;
    });
    validMovements = validMovements.filter((mov) => {
      return (
        this.getDateWithoutTime(DateTime.fromISO(mov.checkOut)) > this.getDateWithoutTime(startDate)
      );
    });
    // temporary solution: if I have movements after requested dates I take the last movement's checkout date as a first available date for new booking.
    if (validMovements.length > 1) {
      //TODO: implement this logic: I need to check if between existing movements I can put a new one considering periodInMonth chosen bu user
      const endDate = DateTime.fromISO(validMovements[validMovements.length - 1].checkOut);
      date = endDate.plus({ days: 1 });
    } else if (validMovements.length === 1) {
      const endDate = DateTime.fromISO(validMovements[0].checkOut);
      date = endDate.plus({ days: 1 });
    } else {
      //all the movements are or 'interesse' or 'scaduto'
      //in this case I have to use or availableFrom of an apartment or start date in case available from is before start date
      if (
        apartment.availableFrom &&
        this.getDateWithoutTime(startDate) < this.getDateWithoutTime(apartment.availableFrom)
      ) {
        date = apartment.availableFrom;
      } else {
        date = startDate;
      }
    }
    return date;
  };

  getFirstAvailableStartDate = (apartments: ApartmentPublic[]): DateTime => {
    if (apartments.length) {
      const sortedApts = cloneDeep(apartments);
      sortedApts.sort((apt1, apt2) => {
        if (
          this.getDateWithoutTime(apt1.availableFrom) < this.getDateWithoutTime(apt2.availableFrom)
        ) {
          return -1;
        } else if (
          this.getDateWithoutTime(apt1.availableFrom) > this.getDateWithoutTime(apt2.availableFrom)
        ) {
          return 1;
        } else {
          return 0;
        }
      });
      return sortedApts[0].availableFrom;
    } else {
      return null; // non dovrebbe mai succedere
    }
  };
  isOccupied = (apartment: ApartmentPublic, dates: DateState): boolean => {
    const start = this.getDateWithoutTime(dates.start.date);
    // const end = this.getDateWithoutTime(dates.end.date);
    const occupied = apartment.movements.filter((mov: Movement) => {
      //devo verificare se tutti i movement in stato occupato (proposta, rogitato, opzionato, temporaneamente_sospeso) rispettano la seguente logica:
      // 1. la data di checkout del movement e' prima di 'start'
      // 2. OPPURE la data di checkin e' dopo di 'end'
      // devo scegliere quelli che NON seguono quella logica
      // const checkIn = mov.checkIn && this.getDateWithoutTime(DateTime.fromISO(mov.checkIn));
      const checkOut = mov.checkOut && this.getDateWithoutTime(DateTime.fromISO(mov.checkOut));

      // Condizione valida solo quando sarà considerata Legalmente possibile prenotare in intervalli tra 2 mov
      // if (this.isUnavailableMovement(mov) && ((checkOut && checkOut < start) || (checkIn && checkIn > end))) {
      if (
        (this.isUnavailableMovement(mov) && checkOut && checkOut < start) ||
        !this.isUnavailableMovement(mov)
      ) {
        return false;
      } else {
        return true;
      }
    });
    return occupied.length > 0;
  };

  isReady = (apartment: ApartmentPublic, dates: DateState): boolean => {
    return (
      (apartment.new &&
        this.getDateWithoutTime(apartment.availableFrom) <=
          this.getDateWithoutTime(dates.start.date)) ||
      !apartment.new
    );
  };

  getDateWithoutTime = (date: DateTime): DateTime => {
    return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  };

  buildAptPublic = (
    apt: ApartmentPublic,
    daysToBeReady: number,
    monthsToExclude: Array<MonthsToExclude>,
  ): void => {
    if (
      this.isGreenField(apt) &&
      apt.movements.every(
        // no current rented movements and no old rented movements
        (mv: Movement) => mv.status !== Status.ROGITATO && mv.status !== Status.SCADUTO,
      )
    ) {
      if (
        //FIXME:consider expiryOn
        apt.movements.every(
          (mv: Movement) => mv.status !== Status.OPZIONATO && mv.status !== Status.PROPOSTA,
        ) // just 'interesse' || 'temporaneamente_sospeso'
      ) {
        apt.new = true;
        const exactDateWhenApartmentIsReady = this.getExactDateWhenApartmentIsReady(daysToBeReady);
        const dateAfterMonthsExcluded = this.getAvailableDateAfterMonthsExcluded(
          exactDateWhenApartmentIsReady,
          monthsToExclude,
        );
        apt.availableFrom = this.getRoundedDate(dateAfterMonthsExcluded);
      } else {
        //it can happen that apartment has no mood or packs but still occupied by someone (in case of status 'proposta' or 'opzionato')
        //in this case apartment isn't new but mood probably is not defined yet, so we shouldn't consider this type of apartment as free
        apt.new = false;
        apt.availableFrom = this.getFirstValidDate(); //put first valid date even if an apartment is excluded from the search
        apt.active = false;
      }
    } else if (
      //in this case apartment was rented in past but free now
      apt.movements.every((mv: Movement) => mv.status !== Status.ROGITATO) &&
      apt.movements.some((mv: Movement) => mv.status === Status.SCADUTO)
    ) {
      apt.new = false;
      apt.availableFrom = this.getFirstValidDate();
    } else {
      //in this case or an apartment is occupied and we need first free date
      // or by chance we have mood chosen but apartment is free
      //FIXME: render an apartment free 6 month before checkout
      apt.new = false;
      apt.availableFrom = this.getFirstFreeDate(apt);
    }
  };

  private isUnavailableMovement = (movement: Movement) => {
    return (
      movement.status === Status.ROGITATO ||
      movement.status === Status.OPZIONATO ||
      movement.status === Status.PROPOSTA ||
      movement.status === Status.TEMPORANEAMENTE_SOSPESO
    );
  };

  private getFirstFreeDate = (apartment: ApartmentPublic) => {
    let date = this.getDateWithoutTime(DateTime.now());
    const unavailableMovements = apartment.movements.filter((mv) => this.isUnavailableMovement(mv));
    if (unavailableMovements.length) {
      unavailableMovements.forEach((mov) => {
        if (mov.checkOut && this.getDateWithoutTime(DateTime.fromISO(mov.checkOut)) > date) {
          date = this.getDateWithoutTime(DateTime.fromISO(mov.checkOut).plus({ days: 1 }));
        } else if (!mov.checkOut) {
          date = this.getDateWithoutTime(DateTime.now().plus({ years: 8 }));
        }
      });
    } else {
      //puo' succedere che tutti i movement sono in stato interesse ma c'e' gia' mood scelto,
      // proponiamo quel appartamento subito
      return this.getDateWithoutTime(this.getFirstValidDate());
    }
    return date;
  };

  private isGreenField(apt: ApartmentPublic) {
    return !apt.mood && (!apt.packsList || apt.packsList.length === 0);
  }
}
