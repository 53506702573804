import { gql } from '@apollo/client';

const BUILDING_DATA_TO_RETRIEVE = `
    name
    complex
`;

export const GET_BUILDINGS_PUBLIC = gql`
  query getBuildingsPublic($project_id :ID!) {
    getBuildingsPublic(project_id: $project_id) {
      ${BUILDING_DATA_TO_RETRIEVE}
    }
}`;

export const GET_BUILDINGS_PUBLIC_DEFAULT_OPTIONS = (pName: string) => {
  return {
    variables: {
      project_id: pName,
    },
  };
};
