import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import moment from 'moment';
import Client from '../../client/Client';
import { setTokenUser, unsetLoggedUser } from '../../store/slices/auth';
import { IToken } from '../../types/auth';
import GetDomain from '../../utils/GetDomain';
import { logger } from '@tecma/logs-fe';
import { refreshPage } from '../../utils/utils';

let timeoutRefreshToken: number = null;

const TokenCountdown = ({ threshold }: { threshold: string }) => {
  const userToken = useAppSelector((state) => state.auth.token);
  const userEmail = useAppSelector((state) => state.auth.user);
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [refreshToken] = useMutation(Client.REFRESH_TOKEN);

  const expiresTokenIn = userToken && userToken.expiresIn;
  const domain = GetDomain();

  const cookieOptions = {
    expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
    domain: domain,
  };

  useEffect(() => {
    startCountDown(expiresTokenIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const deleteCookies = () => {
    Cookies.remove('jwtRent', { domain: domain });
    Cookies.remove('refreshTokenRent', { domain: domain });
    Cookies.remove('expiresInRent', { domain: domain });
    Cookies.remove('clientIdRent', { domain: domain });
  };

  const setNewCookies = () => {
    if (
      moment(Cookies.get('expiresInRent')).isAfter(moment.now()) &&
      Cookies.get('jwtRent') &&
      Cookies.get('refreshTokenRent') &&
      Cookies.get('expiresInRent')
    ) {
      const token: IToken = {
        accessToken: Cookies.get('jwtRent'),
        refreshToken: Cookies.get('refreshTokenRent'),
        expiresIn: Cookies.get('expiresInRent'),
      };
      dispatch(setTokenUser(token));
    }
  };

  const logOut = () => {
    deleteCookies();
    dispatch(unsetLoggedUser());
    refreshPage();
  };

  const startCountDown = (expiresIn: string) => {
    const targetTime = +new Date(expiresIn) - +threshold;
    const baseTimeout = targetTime - Date.now();
    const maxTimeoutValue = 2147483647;
    const timeout = baseTimeout > maxTimeoutValue ? maxTimeoutValue : baseTimeout;
    if (timeout > 0) {
      clearTimeout(timeoutRefreshToken);
      timeoutRefreshToken = window.setTimeout(() => {
        getNewToken(timeout, baseTimeout);
      }, timeout);
    } else if (auth.token && auth.token.accessToken && auth.token.expiresIn) {
      const exp = moment(auth.token.expiresIn);
      if (exp.isBefore(moment.now())) {
        logOut();
      }
    }
  };

  const getNewToken = async (timeout: number, baseTimeout: number) => {
    const logout = Cookies.get('doLogout');
    try {
      if (
        logout === 'false' &&
        userEmail &&
        baseTimeout >= 0 &&
        userToken.refreshToken &&
        expiresTokenIn === Cookies.get('expiresInRent')
      ) {
        refreshToken(
          Client.REFRESH_TOKEN_DEFAULT_OPTIONS({
            refreshToken: userToken && userToken.refreshToken,
            email: userEmail && userEmail.email,
          }),
        )
          .then((response) => {
            if (response.data) {
              const newTokenObject: IToken = response.data.refreshToken;
              Cookies.set('jwtRent', newTokenObject.accessToken, cookieOptions);
              Cookies.set('refreshTokenRent', newTokenObject.refreshToken, cookieOptions);
              Cookies.set('expiresInRent', newTokenObject.expiresIn, cookieOptions);
              dispatch(setTokenUser(newTokenObject));
            }
          })
          .catch(() => {
            if (expiresTokenIn !== Cookies.get('expiresInRent')) {
              setNewCookies();
            } else {
              logOut();
            }
          });
      } else if (logout === 'false' && expiresTokenIn !== Cookies.get('expiresInRent')) {
        setNewCookies();
      } else if (baseTimeout < 0 || logout === 'true') {
        logOut();
      }
    } catch (e) {
      logger.error(e);
      setTimeout(() => {
        getNewToken(timeout, baseTimeout);
      }, timeout);
    }
  };

  return <></>;
};

export default TokenCountdown;
