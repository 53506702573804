import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { APARTMENT_PREPARATION_DAYS } from '../../config/defaultConfig';
import {
  ComponentParams,
  MonthsToExclude,
  MoodColors,
  ProjectInfo,
  SliderImagesNumber,
  Step,
} from '../../types/info';
import { Plan } from '../../types/rent';
import {
  getNewStepStatus,
  getSteps,
  isStepActive,
  resetStepsStatus,
} from '../functions/infoFunctions';

export interface InfoState {
  projectId: string;
  projectTitle: string;
  projectName: string;
  projectDisplayName: string;
  locale: string;
  defaultLang: string;
  hostKey: string;
  vendor: string;
  language: string;
  steps: Step[];
  currentStep: number;
  componentParams: ComponentParams;
  lightboxModal: boolean;
  planToShow: Plan;
  showContactForm: boolean;
  showSummary: boolean;
  showPlanImage: boolean;
  showPicker: boolean;
  showPickerModal: boolean;
  showSoldOutPopup: boolean;
  animation: boolean;
  showAuthModal: boolean;
  modeAuthModal: string;
  assetLogin?: string[];
  startDate: string;
  loading: boolean;
  title?: string;
  moodColors?: MoodColors;
  daysToBeReady?: number;
  monthsToExclude?: Array<MonthsToExclude>;
  languages: string[];
  isTotalRateWithoutExpenses?: boolean;
}

const initialState: InfoState = {
  projectId: '',
  projectTitle: '',
  projectName: '',
  projectDisplayName: '',
  locale: 'en-US',
  defaultLang: navigator.language,
  hostKey: '',
  vendor: '',
  language: '',
  steps: [],
  currentStep: 1,
  componentParams: null,
  lightboxModal: false,
  planToShow: null,
  showContactForm: false,
  showSummary: false,
  showPlanImage: false,
  showPicker: false,
  showPickerModal: false,
  showSoldOutPopup: false,
  assetLogin: [],
  animation: true,
  showAuthModal: false,
  modeAuthModal: '',
  startDate: null,
  loading: false,
  title: '',
  moodColors: null,
  daysToBeReady: APARTMENT_PREPARATION_DAYS,
  monthsToExclude: [],
  languages: [],
  isTotalRateWithoutExpenses: false,
};

export const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setShowAuthMode: (state, action: PayloadAction<{ show: boolean; mode: string }>) => {
      state.showAuthModal = action.payload.show;
      state.modeAuthModal = action.payload.mode;
    },
    setProjectInfo: (state, action: PayloadAction<ProjectInfo>) => {
      const project = action.payload;
      return {
        ...state,
        projectId: project.id,
        projectTitle: project.pageTitles.floorPlanning,
        projectName: project.name,
        projectDisplayName: project.displayName,
        hostKey: project.hostKey,
        locale: project.locale,
        defaultLang: project.defaultLang,
        startDate: project.floorPlanningRentConfig.startDate || null,
        vendor: project.floorPlanningRentConfig.vendor,
        language: project.floorPlanningRentConfig.language,
        steps: getSteps(project.floorPlanningRentConfig.steps),
        componentParams: project.floorPlanningRentConfig.componentParams,
        assetLogin: project.assetLogin,
        title:
          project.pageTitles && project.pageTitles.floorPlanning
            ? project.pageTitles.floorPlanning
            : 'Floor-Planning',
        moodColors: project.floorPlanningRentConfig.style?.moodColors,
        daysToBeReady: project.rentAssetContext?.daysToBeReady || APARTMENT_PREPARATION_DAYS,
        monthsToExclude: project.rentAssetContext?.monthsToExclude || [],
        languages: project.floorPlanningRentConfig.languages,
        isTotalRateWithoutExpenses: project.rentAssetContext?.isTotalRateWithoutExpenses || false,
      };
    },

    setSteps: (state, action: PayloadAction<Step[]>) => {
      state.steps = action.payload;
    },

    setStepStatus: (state, action: PayloadAction<{ stepIndex: number; currentStatus: string }>) => {
      let steps = [...state.steps];
      const index = action.payload.stepIndex;
      const step = steps.find((stepItem) => stepItem.index === index);
      const stepIndex = steps.indexOf(step);
      const status = getNewStepStatus(action.payload.currentStatus);
      const active = isStepActive(action.payload.currentStatus);
      if (!active && index < steps.length) {
        const nextStep = steps.find((nextStepItem) => nextStepItem.index === index + 1);
        const nextStepIndex = steps.indexOf(nextStep);
        nextStep.active = true;
        steps[nextStepIndex] = nextStep;
      }
      step.status = status;
      step.active = active;
      steps[stepIndex] = step;
      const currentStep = step.index;
      if (
        currentStep < state.currentStep ||
        (status === 'conferma' && currentStep <= state.currentStep)
      ) {
        steps = resetStepsStatus([...steps], currentStep);
      }
      state.currentStep = currentStep;
      state.steps = steps;
    },

    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },

    toggleStep: (state, action: PayloadAction<{ currentStepIndex: number; showStep: boolean }>) => {
      const steps = [...state.steps];
      const step = steps.find((stepItem) => stepItem.index === action.payload.currentStepIndex);
      const index = steps.indexOf(step);
      step.show = action.payload.showStep;
      steps[index] = step;
      state.steps = steps;
    },

    toggleSummaryPanel: (state, action: PayloadAction<boolean>) => {
      state.showSummary = action.payload;
    },

    setLightBoxModal: (state, action: PayloadAction<boolean>) => {
      state.lightboxModal = action.payload;
    },

    setPlanToShow: (state, action: PayloadAction<Plan>) => {
      state.planToShow = action.payload;
    },

    setShowContactForm: (state, action: PayloadAction<boolean>) => {
      state.showContactForm = action.payload;
    },

    setShowPlanImage: (state, action: PayloadAction<boolean>) => {
      state.showPlanImage = action.payload;
    },

    setShowPicker: (state, action: PayloadAction<boolean>) => {
      state.showPicker = action.payload;
    },

    setShowPickerModal: (state, action: PayloadAction<boolean>) => {
      state.showPickerModal = action.payload;
    },

    setShowSoldOutPopup: (state, action: PayloadAction<boolean>) => {
      state.showSoldOutPopup = action.payload;
    },

    toggleAnimation: (state, action: PayloadAction<boolean>) => {
      state.animation = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setCurrentStep,
  setLightBoxModal,
  setLoading,
  setPlanToShow,
  setProjectInfo,
  setShowAuthMode,
  setShowContactForm,
  setShowPicker,
  setShowPickerModal,
  setShowPlanImage,
  setShowSoldOutPopup,
  setStepStatus,
  setSteps,
  toggleAnimation,
  toggleStep,
  toggleSummaryPanel,
} = infoSlice.actions;

export const sortedAvailableLanguagesSelector = (state: RootState): string[] =>
  state.info.languages.slice().sort();
export const getSliderImages = (state: RootState): SliderImagesNumber =>
  state.info.componentParams.sliderImagesNumber;

export default infoSlice.reducer;
