import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../store/hooks';

import './icon.scss';

export interface IconProps {
  name: string;
  size?: 'default' | 'small' | 'large';
  colorBG?: string;
  colorIcon?: string;
  className?: string;
}

/**
 * This components returns as div with an svg icon inside fetched from bucket
 */
const Icon: React.FC<IconProps> = ({ name, size = 'default', className, colorIcon, colorBG }) => {
  const isMounted = useRef(true);
  const [svg, setSvg] = useState(null);
  const projectDisplayName = useAppSelector((store) => store.info.projectDisplayName);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getIcon = async () => {
    let url;
    if (name === 'primary-logo' || name === 'header-logo') {
      url = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
        projectDisplayName,
      )}/global/img/${name}.svg`;
    } else {
      url = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
        projectDisplayName,
      )}/floorplanning-rent/img/icons/${name}.svg`;
    }
    const callbackUrl = `${process.env.REACT_APP_BUCKET_BASEURL}/floorplanning-rent/img/icons/${name}.svg`;
    const response = await fetch(url);
    if (response.status !== 404) {
      const icon = await response.text();
      if (icon && isMounted.current) {
        setSvg(icon);
      }
    } else if (name !== 'header-logo') {
      const callbackResponse = await fetch(callbackUrl);
      if (callbackResponse.status !== 404) {
        const defaultIcon = await callbackResponse.text();
        if (defaultIcon && isMounted.current) {
          setSvg(defaultIcon);
        }
      }
    }
  };

  useEffect(() => {
    getIcon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classList = classNames(
    'rent-icon',
    {
      [`icon-${size}`]: size,
      [`icon-${colorIcon}`]: colorIcon,
      [`icon-${colorBG}`]: colorBG,
    },
    className,
  );
  return <span className={classList} dangerouslySetInnerHTML={{ __html: svg }} />;
};

export default React.memo(Icon);
