import React, { Fragment, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Trans, useTranslation } from '@tecma/i18n';
import { NetworkStatus, useQuery } from '@apollo/client';

import Client from '../../client/Client';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import NotificationBox from '../common/NotificationBox';
import StepOptionHeader from '../common/StepOptionHeader';
import { Loader } from '../Loader';
import {
  addSelectedFeature,
  getIncludedFeature,
  removeSelectedFeature,
  resetSelectedFeatures,
  selectAvailableFeatures,
  getSelectedFeatures,
  setAvailableFeatures,
} from '../../store/slices/rent';
import { ExtraFeature } from '../../types/rent';
import './pickers.scss';

interface ExtraSpacePicker {
  stepName: string;
}

const ExtraSpacePicker: React.FC<ExtraSpacePicker> = ({ stepName }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const rent = useAppSelector((state) => state.rent);
  const projectId = useAppSelector((state) => state.info.projectId);
  const apartmentType = useAppSelector((state) => state.rent?.type);
  const apartmentBuildingID = useAppSelector((state) => state.rent?.apartmentInfo?.buildingID);
  const { start, end } = useAppSelector((state) => state.dates);
  const enabledFeaturesType = useAppSelector((state) => state.info.componentParams.enabledFeatures);
  const includedFeature = useAppSelector(getIncludedFeature);
  const availableFeatures = useAppSelector(selectAvailableFeatures);
  const selectedFeatures = useAppSelector(getSelectedFeatures);
  const maxBoxCellarNumber = useAppSelector(
    (state) => state.info.componentParams.maxBoxCellarNumber,
  );
  const steps = useAppSelector((state) => state.info.steps);

  const step = steps.find((stepItem) => stepItem.name === stepName);

  const featuresInRange = useQuery<{
    getSpacesInRange: { ['FEATURES']: { [key: string]: ExtraFeature[] } };
  }>(
    Client.GET_FEATURES_IN_RANGE,
    Client.GET_FEATURES_IN_RANGE_OPTIONS(
      projectId,
      {
        startDate: start.date.toISO({ includeOffset: false }),
        endDate: end.date.toISO({ includeOffset: false }),
        types: ['FEATURES'],
      },
      { typology: apartmentType, building_id: apartmentBuildingID },
    ),
  );

  useEffect(() => {
    if (featuresInRange?.data?.getSpacesInRange) {
      const availableFeaturesData = featuresInRange.data.getSpacesInRange.FEATURES;
      dispatch(resetSelectedFeatures());
      dispatch(setAvailableFeatures(availableFeaturesData));
    }
  }, [dispatch, includedFeature, featuresInRange.data]);

  useEffect(() => {
    if (featuresInRange.called) {
      featuresInRange.refetch({
        input: {
          startDate: start.date.toISO({ includeOffset: false }),
          endDate: end.date.toISO({ includeOffset: false }),
          types: ['FEATURES'],
        },
      });
    }
  }, [start, end]);

  const isIncluded = () => {
    return !rent.apartmentInfo?.cellarInfo?.isEmpty;
  };

  const hasOptions = () => {
    return (
      enabledFeaturesType.length && enabledFeaturesType.some((feature) => feature.name !== 'cellar')
    );
  };

  const getTitle = (featureType: string) => {
    return (
      <p>
        <Trans
          i18nKey={`configurator.${featureType}`}
          components={{ 1: <strong className="block-space-text-strong" /> }}
        />
      </p>
    );
  };

  const getFeaturesSelected = (featureType: string) => {
    const result = selectedFeatures?.[featureType]?.length || 0;
    return result;
  };

  const getOptionsValue = (type: string): number => {
    return availableFeatures?.[type]?.length || 0;
  };

  const getMaxFeaturesSelectable = (type: string): number => {
    return Math.min(getOptionsValue(type), maxBoxCellarNumber);
  };

  const handleOnClickAdd = (featureType: string) => {
    if (getFeaturesSelected(featureType) !== getMaxFeaturesSelectable(featureType)) {
      dispatch(addSelectedFeature(featureType));
    }
  };

  const handleOnClickMinus = (featureType: string) => {
    if (getFeaturesSelected(featureType)) {
      dispatch(removeSelectedFeature(featureType));
    }
  };

  const featureBlock = (featureName: string, featureType: string) => (
    <div className="extra-space-box-select">
      <div className="extra-space-box-text">{getTitle(featureName)}</div>
      {[NetworkStatus.loading, NetworkStatus.refetch].includes(featuresInRange.networkStatus) ? (
        <Loader type={'ThreeDots'} cssClass="extra-space" width={50} height={50} />
      ) : (
        <div className="extra-space-option-selector-container">
          {getOptionsValue(featureType) ? (
            <div className="extra-space-option-selector">
              <div className="arrow-select">
                <div
                  className={`arrow-btn-select plus-minus ${
                    getFeaturesSelected(featureType) === 0 ? 'disable' : ''
                  }`}
                  onClick={() => handleOnClickMinus(featureType)}
                >
                  <div>-</div>
                </div>
              </div>
              <div className="option picker">
                <div className="option-label picker"> {getFeaturesSelected(featureType)} </div>
              </div>
              <div className="arrow-select">
                <div
                  className={`arrow-btn-select plus-minus ${
                    getFeaturesSelected(featureType) === getMaxFeaturesSelectable(featureType)
                      ? 'disable'
                      : ''
                  }`}
                  onClick={() => handleOnClickAdd(featureType)}
                >
                  <div>+</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="sold-out-extra-space-container">
              <div className="sold-out-extra-space">{t('configurator.out-of-stock')}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className="option-container no-padding">
      <Scrollbars>
        <div className="extra-space-container">
          {hasOptions() && <StepOptionHeader headerName={step.name} />}
          {enabledFeaturesType.map(({ name, type }) => {
            return (
              <Fragment key={type}>
                {type === 'CANTINA' && isIncluded() && (
                  <NotificationBox
                    show={true}
                    text={t('configurator.cellar-included')}
                    classCss="cellar"
                  />
                )}
                {type === 'CANTINA' && <StepOptionHeader headerName={`${step.name}.cellars`} />}
                {featureBlock(name, type)}
              </Fragment>
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
};
export default ExtraSpacePicker;
