import { gql } from '@apollo/client';

export interface TypologyInput {
  code?: string;
  name: string;
  rooms: string;
  formulas: string[];
  active?: boolean;
  desk_visibility?: boolean;
  web_visibility?: boolean;
  temp_desk_visibility?: boolean;
  temp_web_visibility?: boolean;
  visits?: number;
}

const TYPOLOGY_DATA_TO_RETRIEVE = `
    id
    name
    desk_visibility
    web_visibility
    temp_web_visibility
    temp_desk_visibility
    active
    count
    minPrice
    rooms
`;

export const UPDATE_TYPOLOGY_MUTATION = gql`
mutation updateTypologies($project_id :ID!, $typologyId: ID!, $typologyInput: TypologyInput!) {
  updateTypologies(project_id: $project_id, id: $typologyId, input: $typologyInput) {
        ${TYPOLOGY_DATA_TO_RETRIEVE}
        }
    }
`;

export const UPDATE_TYPOLOGY_OPTIONS = (
  project_id: string,
  typologyId: string,
  typologyInput: TypologyInput,
) => {
  return {
    variables: {
      project_id,
      typologyId,
      typologyInput,
    },
    fetchPolicy: 'no-cache',
  };
};
