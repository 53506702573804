import { gql } from '@apollo/client';
import { EmailInfoData } from '../../types/contact';

const QUOTE_DATA_TO_RETRIEVE = `
  id  
  client {
    id
  }
  vendor
  appartment {
    name
    id
    floor
    price
    discounted_price
    available
    active
    code
    enabled_web
    enabled_desk
    status
    selectedBy
    {
      id
      client {
        id
      }
    }
    plan
    {
    name
    id
    model {
        id
        name
        visits
    }
    typology {
        id
        name
        visits
        rooms
        formulas {
          id
          increase
          name: label
          shortDescription
          description: longDescription
        }
    }
    SuperficieGiardino
    SuperficieTotale
    SuperficieTerrazzo
    SuperficieAppartamento
    SuperficieLoggia
    visits
    image
    }
    sides {
      id
      name
      building {
        id
        name
        floors
      }
    }
    standardQuote {
      
      boxes {
        box
        price
        meters
        floor
      }
      basements {
        basement
        price
        meters
        floor
      }

      parkingSpace_bykes {
        parkingSpace_byke
        price
        meters
        floor
      }
      
      promos {
        promo
        percentage
        name
        info
        class
        price
        endDate
      }
      payments {
        name
        endDate
        price
        percentage
        daysForPayment
        notes
      }
      expenses {
        name
        price
        percentage
        emptyLabel
      }

      iva {
        name
        percentage
      }
      
      promoPrice
      fixedRate {
        percentage
        value
      }
      variableRate {
        percentage
        value
      }
      rate
    }
  }
  reserved
  customQuote 
  {
    boxes {
      box
      price
      meters
      floor
    }
    basements {
      basement
      price
      meters
      floor
    }
    parkingSpace_bykes {
      parkingSpace_byke
      price
      meters
      floor
    }
    promos {
      promo
      percentage
      name
      info
      class
      price
      endDate
    }
    payments {
      name
      endDate
      price
      percentage
      daysForPayment
      notes
    }
    expenses {
      name
      price
      percentage
      emptyLabel
    }
    
    promoPrice
    fixedRate {
      percentage
      value
    }
    variableRate {
      percentage
      value
    }
    rate
    freeNotes
  }
`;

export const GET_QUOTE_BY_CLIENT = gql`
  query getQuotesByClient($project_id :ID!, $client: ID!) {
      getQuotesByClient(project_id: $project_id, client: $client) {
      ${QUOTE_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_QUOTE = gql`
  query getQuote($project_id :ID!, $id: ID!) {
    getQuote(project_id: $project_id, id: $id) {
      ${QUOTE_DATA_TO_RETRIEVE}
      }
  }
`;

export const CREATE_QUOTE_MUTATION = gql`
mutation createQuotesRent($project_id :ID!, $input: QuoteRentInput!, $sendClientEmail: Boolean, $emailInfo: JSON) {
  createQuotesRent(project_id: $project_id, input: $input, sendClientEmail: $sendClientEmail, emailInfo: $emailInfo) {
        ${QUOTE_DATA_TO_RETRIEVE}
        }
    }
`;

export const UPDATE_QUOTE_MUTATION = gql`
mutation updateQuotesRent($project_id :ID!, $quoteId: ID!, $quoteInput: QuoteRentInput!) {
  updateQuotesRent(project_id: $project_id, id: $quoteId, input: $quoteInput) {
        ${QUOTE_DATA_TO_RETRIEVE}
        }
    }
`;

export const SEND_QUOTE_MUTATION = gql`
  mutation sendQuote($project_id: ID!, $quoteInput: QuoteInput!) {
    sendQuote(project_id: $project_id, input: $quoteInput)
  }
`;

export const GET_QUOTE_BY_CLIENT_OPTIONS = (project_id: string, client: string) => {
  return {
    variables: {
      project_id,
      client,
    },
    fetchPolicy: 'network-only',
  };
};

export const GET_QUOTE_OPTIONS = (project_id: string, id: string) => {
  return {
    variables: {
      project_id,
      id,
    },
    fetchPolicy: 'network-only',
  };
};

export interface CreateQuoteOptions {
  variables: {
    project_id: string;
    input: QuoteRentInput;
    sendClientEmail: boolean;
    emailInfo: EmailInfoData;
  };
  fetchPolicy: 'no-cache';
}

export interface QuoteParkingSpace_bykesInput {
  parkingSpace_byke?: string;
  price?: number;
  meters?: string;
  floor?: number;
}

export interface QuoteBoxInput {
  box?: string;
  price?: number;
  floor?: number;
  meters?: string;
}

export interface QuoteBasementInput {
  basement?: string;
  price?: number;
  floor?: number;
  meters?: string;
}
export interface QuoteParkingSpace_carsInput {
  parkingSpace_car?: string;
  price?: number;
  floor?: number;
  meters?: string;
}
export interface QuotePromoInput {
  promo?: string;
  name?: string;
  info?: string;
  endDate?: string;
  percentage?: number;
  type?: string;
  price?: number;
  class?: string;
  notes?: string;
}

export interface QuotePaymentInput {
  name?: string;
  endDate?: string;
  price?: number;
  notes?: string;
  percentage?: number;
  daysForPayment?: number;
  type?: string;
}

export interface QuoteExpensesInput {
  name?: string;
  price?: number;
  percentage?: number;
  info?: string;
  emptyLabel?: string;
  type?: string;
}

export interface QuoteIvaInput {
  percentage?: number;
  name?: string;
}

export interface CapitolatoInput {
  price?: number;
  name?: string;
}

export interface QuoteRateInput {
  percentage?: number;
  value?: number;
}

export interface Feature {
  id?: string;
  name?: string;
  type?: string;
  price?: number;
  floor?: number;
  notes?: string;
  code?: string;
  meters?: string;
  width?: string;
  length?: string;
  visible?: boolean;
  available?: boolean;
  createdOn: string;
  status: string;
}

export interface StandardQuoteInput {
  boxes?: QuoteBoxInput[];
  basements?: QuoteBasementInput[];
  parkingSpace_bykes?: QuoteParkingSpace_bykesInput[];
  parkingSpace_cars?: QuoteParkingSpace_carsInput[];
  promos?: QuotePromoInput[];
  payments?: QuotePaymentInput[];
  expenses?: QuoteExpensesInput[];
  iva?: QuoteIvaInput[];
  promoPrice: number;
  capitolato?: CapitolatoInput;
  fixedRate?: QuoteRateInput;
  totalPrice?: number;
  variableRate?: QuoteRateInput;
  rate?: number;
  features?: string[];
  freeNotes?: string;
  building?: string;
}

export interface QuoteRentInput {
  client: string;
  vendor?: string;
  appartment: string;
  status?: string;
  customQuote: StandardQuoteInput;
  expiryOn?: string;
  checkIn?: string;
  checkOut?: string;
}

export const CREATE_QUOTE_OPTIONS = (
  project_id: string,
  input: QuoteRentInput,
  sendClientEmail: boolean,
  emailInfo: EmailInfoData,
) => {
  const options: CreateQuoteOptions = {
    variables: {
      project_id,
      input,
      sendClientEmail,
      emailInfo,
    },
    fetchPolicy: 'no-cache',
  };
  return options;
};

export const UPDATE_QUOTE_OPTIONS = (
  project_id: string,
  quoteId: string,
  quoteInput: QuoteRentInput,
) => {
  return {
    variables: {
      project_id,
      quoteId,
      quoteInput,
    },
    fetchPolicy: 'no-cache',
  };
};
