import {
  getNextDay,
  getPreviousDay,
  getNextMonth,
  getDateObject,
  getNextYear,
  isValidStart,
  isValidEnd,
  DateObject,
  setDayAndMonth,
  getNextYearWithDay,
  getPreviousYearWithDay,
  getFirstValidDate,
  getValidEndDate,
} from '../functions/dateFunctions';
import { DateTime } from 'luxon';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DateState {
  start: DateObject;
  end: DateObject;
  minMonthDifference: number;
}

const initStartDate = getFirstValidDate();
const initEndDate = getValidEndDate(initStartDate);

const initialState: DateState = {
  start: getDateObject(initStartDate),
  end: getDateObject(initEndDate),
  minMonthDifference: 12,
};

export const datesSlice = createSlice({
  name: 'dates',
  initialState,
  reducers: {
    setURLDates: (state, action: PayloadAction<{ startDate: DateTime; endDate: DateTime }>) => {
      const startDate = action.payload.startDate;
      const endDate = action.payload.endDate;
      if (getFirstValidDate() <= startDate) {
        return {
          ...state,
          start: getDateObject(startDate, false),
          end: getDateObject(endDate),
        };
      } else if (startDate < endDate) {
        const start = getFirstValidDate();
        const period = endDate.diff(startDate, ['months', 'days', 'years']).toObject();
        const end = getValidEndDate(start, state.minMonthDifference, period);
        return {
          ...state,
          start: getDateObject(start, false),
          end: getDateObject(end),
        };
      } else {
        return state;
      }
    },
    setMinMonthDifference: (state, action: PayloadAction<{ monthDifference: number }>) => {
      return {
        ...state,
        minMonthDifference: action.payload.monthDifference,
      };
    },
    setStartDate: (state, action: PayloadAction<{ startDate: DateTime; endDate?: DateTime }>) => {
      const newStartDate = action.payload.startDate;
      if (DateTime.now() < newStartDate && !action.payload.endDate) {
        return {
          ...state,
          start: getDateObject(newStartDate, false),
          end: getDateObject(getValidEndDate(newStartDate)),
        };
      } else if (
        DateTime.now() < newStartDate &&
        action.payload.endDate &&
        isValidEnd(newStartDate, action.payload.endDate, state.minMonthDifference)
      ) {
        return {
          ...state,
          start: getDateObject(newStartDate, false),
          end: getDateObject(action.payload.endDate),
        };
      } else return state;
    },
    startDayIncreased: (state, action: PayloadAction<number>) => {
      const begin = getNextDay(state.start.date, action.payload);
      const endDateObject = getDateObject(state.end.date);
      if (state.start.date.day === 1) {
        endDateObject.date = setDayAndMonth(endDateObject.date, endDateObject.date.month + 1, 14);
      } else {
        endDateObject.date = setDayAndMonth(endDateObject.date, endDateObject.date.month, 0);
      }
      return {
        ...state,
        start: isValidStart(begin) ? getDateObject(begin, false) : { ...state.start },
        end: !isValidEnd(begin, state.end.date)
          ? getDateObject(getNextYear(begin, true, false))
          : getDateObject(endDateObject.date),
      };
    },
    startDayDecreased: (state, action: PayloadAction<number>) => {
      const begin = getNextDay(state.start.date, action.payload);
      let endStartDayDecreased = state.end.date;
      if (state.start.date.day === 15) {
        endStartDayDecreased = setDayAndMonth(state.end.date, state.end.date.month - 1, 0);
      } else {
        endStartDayDecreased = setDayAndMonth(state.end.date, begin.month, 14);
      }
      return {
        ...state,
        start: isValidStart(begin) ? getDateObject(begin, false) : { ...state.start },
        end: !isValidEnd(begin, endStartDayDecreased)
          ? getDateObject(getNextYear(begin, true, false))
          : getDateObject(endStartDayDecreased),
      };
    },
    startMonthIncreased: (state, action: PayloadAction<number>) => {
      const begin = getNextMonth(state.start.date, action.payload, false);
      return {
        ...state,
        start: getDateObject(begin),
        end: !isValidEnd(begin, state.end.date)
          ? getDateObject(getNextYear(begin, true, false))
          : { ...state.end },
      };
    },
    startMonthDecreased: (state, action: PayloadAction<number>) => {
      const begin = getPreviousDay(state.start.date, action.payload, false);
      let endStartMonthDecreasedDate = state.end.date;
      if (state.start.date.day === 1) {
        endStartMonthDecreasedDate = setDayAndMonth(state.end.date, state.end.date.month, 14);
      } else {
        endStartMonthDecreasedDate = setDayAndMonth(
          state.end.date,
          state.end.date.month - action.payload,
          0,
        );
      }
      return {
        ...state,
        start: isValidStart(begin) ? getDateObject(begin, false) : { ...state.start },
        end: !isValidEnd(begin, endStartMonthDecreasedDate)
          ? getDateObject(getNextYear(begin, true, false))
          : { ...state.end },
      };
    },
    endMonthIncreased: (state, action: PayloadAction<number>) => {
      let endMonthIncreasedDate = state.end.date;
      if (state.start.date.day === 15) {
        endMonthIncreasedDate = setDayAndMonth(
          state.end.date,
          state.end.date.month + action.payload,
          14,
        );
      } else {
        endMonthIncreasedDate = setDayAndMonth(
          state.end.date,
          state.end.date.month + action.payload,
          0,
        );
      }
      return {
        ...state,
        start: { ...state.start },
        end: getDateObject(endMonthIncreasedDate),
      };
    },
    endMonthDecreased: (state, action: PayloadAction<number>) => {
      let endMonthDecreasedDate = state.end.date;
      if (state.start.date.day === 1) {
        endMonthDecreasedDate = setDayAndMonth(
          state.end.date,
          state.end.date.month - action.payload,
          0,
        );
      } else {
        endMonthDecreasedDate = setDayAndMonth(
          state.end.date,
          state.end.date.month - action.payload,
          14,
        );
      }
      return {
        ...state,
        start: { ...state.start },
        end: isValidEnd(state.start.date, endMonthDecreasedDate)
          ? getDateObject(endMonthDecreasedDate)
          : { ...state.end },
      };
    },
    startYearIncreased: (state) => {
      const begin = getNextYearWithDay(state.start.date);
      return {
        ...state,
        start: isValidStart(begin)
          ? getDateObject(begin, false)
          : getDateObject(state.start.date, false),
        end: !isValidEnd(begin, state.end.date)
          ? getDateObject(getNextYearWithDay(state.end.date))
          : { ...state.end },
      };
    },
    startYearDecreased: (state) => {
      const begin = getPreviousYearWithDay(state.start.date);
      return {
        ...state,
        start: isValidStart(begin)
          ? getDateObject(begin, false)
          : getDateObject(state.start.date, false),
        end: { ...state.end },
      };
    },
    endYearIncreased: (state) => {
      const nextYear = getNextYearWithDay(state.end.date);
      return {
        ...state,
        start: { ...state.start },
        end: getDateObject(nextYear),
      };
    },
    endYearDecreased: (state) => {
      const previousYear = getPreviousYearWithDay(state.end.date);
      return {
        ...state,
        start: { ...state.start },
        end: isValidEnd(state.start.date, previousYear)
          ? getDateObject(previousYear)
          : { ...state.end },
      };
    },
  },
});

export const {
  setURLDates,
  setMinMonthDifference,
  setStartDate,
  startDayDecreased,
  startDayIncreased,
  startMonthDecreased,
  startMonthIncreased,
  startYearDecreased,
  startYearIncreased,
  endMonthDecreased,
  endMonthIncreased,
  endYearDecreased,
  endYearIncreased,
} = datesSlice.actions;

export default datesSlice.reducer;
