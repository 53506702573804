import { useState } from 'react';
import { Trans, useTranslation } from '@tecma/i18n';

import './common.scss';

interface StepOptionHeaderProps {
  headerName: string;
}

const StepOptionHeader: React.FC<StepOptionHeaderProps> = ({ headerName }) => {
  const { t } = useTranslation();
  const [noteView, setNoteView] = useState(false);

  const viewNote = () => (
    <div className="text-info-container">
      <div className="text-info">
        <strong>{t('configurator.info')} </strong>
        {t(`step.${headerName}.info-text`)}
      </div>
    </div>
  );

  return (
    <div className={`info-box-container`}>
      <div className="title-options">
        <Trans
          i18nKey={`step.${headerName}.title`}
          components={{
            1: <br />,
          }}
        />
      </div>
      <div className="box-info">
        <div className="title-info-container">
          <div
            className="info-icon"
            onClick={() => {
              noteView ? setNoteView(false) : setNoteView(true);
            }}
          >
            <div className="info-block-open">{noteView ? 'x' : 'i'}</div>
          </div>
          <div className="title-info">{t(`step.${headerName}.info-title`)}</div>
        </div>
        {noteView && viewNote()}
      </div>
    </div>
  );
};

export default StepOptionHeader;
