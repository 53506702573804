import React from 'react';
import { Trans } from '@tecma/i18n';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setPlan } from '../../store/slices/rent';
import StepOptionHeader from '../common/StepOptionHeader';
import Scrollbars from 'react-custom-scrollbars';
import { Icon } from '../Icon';
import { getAvailablePlans } from '../../store/functions/rentFunctions';

interface PlanPickerProps {
  stepName: string;
}

const PlanPicker: React.FC<PlanPickerProps> = ({ stepName }) => {
  const dispatch = useAppDispatch();
  const planList = useAppSelector((state) => state.rent.planList);
  const selectedPlan = useAppSelector((state) => state.rent.plan);
  const floor = useAppSelector((state) => state.rent.floor);
  const mood = useAppSelector((state) => state.rent.mood);
  const dates = useAppSelector((state) => state.dates);
  const steps = useAppSelector((state) => state.info.steps);
  const step = steps.find((stepItem) => stepItem.name === stepName);
  const availablePlans = getAvailablePlans(planList, floor.number, mood);

  return (
    <div className="option-container no-padding">
      <div className="info-container">
        <StepOptionHeader headerName={step.name} />
      </div>
      <Scrollbars>
        <div className="plan-picker-container">
          {availablePlans.map((plan, key) => (
            <div
              key={key}
              className={`select-plan-button ${plan.name === selectedPlan.name ? `selected` : ``}`}
              onClick={() => dispatch(setPlan({ planName: plan.name, dates }))}
            >
              <Icon
                // name={plan.name === selectedPlan.name ? `planimetry_selected` : `planimetry`} doesn't work
                name={`planimetry`}
                className={`select-plan-button-icon ${
                  plan.name === selectedPlan.name ? `selected` : ``
                }`}
              />
              <div className={`divider ${plan.name === selectedPlan.name ? `selected` : ``}`} />
              <div className="select-plan-button-text">
                <Trans
                  i18nKey="configurator.planimetry-name"
                  components={{
                    1: <span />,
                  }}
                  values={{ name: plan.name }}
                />
              </div>
            </div>
          ))}
        </div>
      </Scrollbars>
    </div>
  );
};

export default PlanPicker;
