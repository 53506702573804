/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SpacePicker from '../components/pickers/SpacePicker';
import FloorPicker from '../components/pickers/FloorPicker';
import MoodPicker from '../components/pickers/MoodPicker';
import PlanPicker from '../components/pickers/PlanPicker';
import OptionalSummary from '../components/OptionalSummary';
import ExtraSpacePicker from '../components/pickers/ExtraSpacePicker';
import { RentState } from '../store/slices/rent';
import {
  DefaultTypes,
  EmailFeaturesCounter,
  ExtraFeature,
  FeaturesCounterFromUnion,
  Pack,
  Plan,
} from '../types/rent';
import { EmailInfoData, InfoData } from '../types/contact';
import { EnabledFeature, SliderImagesNumber, Step } from '../types/info';
import { IUser } from '../types/auth';
import { getMyHomeUrl } from './DetectUrl';
import { ApartmentAvailability, TranslationInfo } from '../types/common';

const capitalizeAndReplace = (string: string) => {
  return string.replace(/\b(\w)/g, (c) => c.toUpperCase()).replace('-', ' ');
};

export const getPicker = (component: string, name: string) => {
  switch (component) {
    case 'SpacePicker': {
      return <SpacePicker stepName={name} />;
    }
    case 'FloorPicker': {
      return <FloorPicker stepName={name} />;
    }
    case 'MoodPicker': {
      return <MoodPicker stepName={name} />;
    }
    case 'PlanPicker': {
      return <PlanPicker stepName={name} />;
    }
    case 'OptionalPicker': {
      return <OptionalSummary stepName={name} />;
    }
    case 'ExtraSpacePicker': {
      return <ExtraSpacePicker stepName={name} />;
    }
    default:
      return '';
  }
};

export const controlUserChoice = (rentStore: RentState, stepName: string) => {
  switch (stepName) {
    case 'space': {
      return rentStore.type !== '';
    }
    default:
      return true;
  }
};

export const getAvailableFloors = (planList: Plan[], isMoodChosen: boolean, mood: string) => {
  const floors: number[] = [];
  planList.forEach((plan) => {
    plan.floors.forEach((floor) => {
      if (
        !floors.includes(floor.number) &&
        (!isMoodChosen || floor.availableMoods.includes(mood))
      ) {
        floors.push(floor.number);
      }
    });
  });
  floors.sort((a, b) => a - b);
  return floors;
};

/**
 * return a list of not selected packs
 */
export const getAvailablePacks = (plaksList: Pack[]) => plaksList.filter((pack) => !pack.active);

/**
 * return a list of all selected packs
 */
export const getSelectedPacks = (packsList: Pack[]) => packsList.filter((pack) => pack.active);

/**
 * return next slide index in carousal
 */
export const getNextSlideIndex = (
  direction: string,
  currentSlideIndex: number,
  maxSlidesNumber: number,
) => {
  let nextIndex = 0;
  if (direction === 'right') {
    if (currentSlideIndex < maxSlidesNumber) {
      nextIndex = currentSlideIndex + 1;
    } else {
      nextIndex = 0;
    }
  } else if (direction === 'left') {
    if (currentSlideIndex > 0) {
      nextIndex = currentSlideIndex - 1;
    } else {
      nextIndex = maxSlidesNumber;
    }
  }
  return nextIndex;
};

/**
 * a number formatter for € currency
 */
export const currencyFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

/**
 * a date formatter
 */
export const dateFormatter = new Intl.DateTimeFormat('it-IT', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

export const dateFormatterLong = new Intl.DateTimeFormat('it-IT', {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});

export const getImageNumbers = (
  sliderImages: SliderImagesNumber,
  complex: string,
  type: string,
  mood: string,
  projectName: string,
) => {
  const imagesNumber = sliderImages?.[complex]?.[type]?.[mood] || 0;
  return Array.from({ length: imagesNumber }, (_, i) => String(i + 1)).map((value) => {
    return `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
      projectName,
    )}/floorplanning-rent/img/slider/${complex}/${type}/${mood}/${value}.jpg`;
  });
};

export const isTablet = () => window.innerWidth <= 1050;

export const isTabletBig = () => window.innerWidth <= 1400;

export const isMobile = () => window.innerWidth <= 540;

export const isMobileSmall = () => window.innerWidth <= 370;

export const includesOneOf = (text: string, patterns: string[]) => {
  return patterns.some((pattern: string) => text.includes(pattern));
};

export const getPackName = (pack: string) => {
  const packName = pack.split('_');
  if (packName[1]) {
    return packName[1];
  }
  return packName[0];
};

export const getPackLabel = (packName: string, complex: string) => {
  return [
    `pack.${packName}.${complex}.label`,
    `pack.${packName}.label`,
    capitalizeAndReplace(packName),
  ];
};

export const uppercaseFormatter = (sentence: string) => {
  const sentenceFormatted = sentence.split(' ').map((word, index) => (
    <div
      key={index}
      className={`${word === word.toUpperCase() ? `am-subtile-bold` : `am-subtile-normal`}`}
    >
      {`${word} `}
    </div>
  ));
  return <>{...sentenceFormatted}</>;
};

export const getMoodName = (moodName: string) => {
  return moodName.replace('-', ' ');
};

export const getMoodNameTranslated = (moodName: string, building: string) => {
  return [`mood.${moodName}.${building}`, `mood.${moodName}`, capitalizeAndReplace(moodName)];
};

export const getEmailData = (user: IUser, info: InfoData) => {
  const emailInfo: EmailInfoData = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    tel: user.tel,
    planName: info.planName,
    apartment: info.apartment,
    otherInfo: {
      checkin: info.start,
      checkout: info.end,
      maxGuestNumber: info.rooms + info.extraGuest,
      floor: info.floor,
      box: info.boxNumber,
      boxMini: info.boxMiniNumber,
      coveredParking: info.coveredParkingNumber,
      uncoveredParking: info.uncoveredParkingNumber,
      autoMotoBox: info.autoMotoBoxNumber,
      doubleBox: info.doubleBoxNumber,
      tripleBox: info.tripleBoxNumber,
      cantina: info.cellarNumber,
      parkingAuto: info.autoNumber,
      parkingMoto: info.motoNumber,
      surfaceApt: info.surfaceApt,
      surfaceTer: info.surfaceTer,
      surfaceGiar: info.surfaceGiar,
      mood: info.mood,
      packs: info.packs,
      totalPrice: info.totalPrice,
      priceNote: info.priceNote,
      boxNote: info.boxNote,
      building: info.building,
    },
  };
  return emailInfo;
};

const getEmailFeatureMappedKey = (
  featureType: string,
  enabledFeatures: EnabledFeature[],
): EmailFeaturesCounter => {
  const valueOfFeatureType = enabledFeatures.find((feat) => feat.type === featureType).name;
  let finalKey = '';
  switch (valueOfFeatureType) {
    case 'parkingMoto':
    case 'parkingAuto':
      finalKey = `${valueOfFeatureType.replace('parking', '').toLowerCase()}`;
      break;
    case 'singleBox':
      finalKey = 'box';
      break;
    default:
      finalKey = valueOfFeatureType;
  }
  return `${finalKey}Number` as EmailFeaturesCounter;
};

const getSelectedFeaturesCount = (
  selectedFeatures: {
    [key: string]: ExtraFeature[];
  },
  enabledFeatures: EnabledFeature[],
): FeaturesCounterFromUnion | null => {
  return selectedFeatures
    ? Object.keys(selectedFeatures).reduce<FeaturesCounterFromUnion>((acc, featureType) => {
        const mappedKey = getEmailFeatureMappedKey(featureType, enabledFeatures);
        acc = { ...acc, [mappedKey]: selectedFeatures[featureType].length };
        return acc;
      }, {} as FeaturesCounterFromUnion)
    : null;
};

export const getEmailInfoData = (
  rent: RentState,
  enabledFeatures: EnabledFeature[],
  start: number,
  end: number,
) => {
  const emailInfoData: InfoData = {
    start: dateFormatter.format(start),
    end: dateFormatter.format(end),
    planName: rent.plan.name,
    rooms: parseInt(rent.plan.typology.rooms),
    extraGuest: rent.plan.extraGuest,
    floor: rent.floor.number,
    surfaceApt: rent.plan.SuperficieAppartamento,
    surfaceTer: rent.plan.SuperficieLoggia,
    surfaceGiar: rent.plan.SuperficieGiardino,
    mood: getMoodName(rent.mood),
    packs: getSelectedPacks(rent.packsList)
      .map((pack) => pack.name)
      .join(', '),
    totalPrice: currencyFormatter.format(Math.round(rent.totalPrice)) + '/mese',
    rate: currencyFormatter.format(Math.round(rent.totalPriceWithoutExpenses)) + '/mese',
    ...getSelectedFeaturesCount(rent.selectedFeatures, enabledFeatures),
    building: rent.apartmentInfo.building,
    apartment: rent.apartmentInfo.name,
  };
  return emailInfoData;
};

export const getStepIndex = (steps: Step[], name: string) => {
  let index = 0;
  if (steps.length) {
    const step = steps.find((st) => st.name === name);
    if (step) {
      index = step.index;
    }
  }
  return index;
};

export const getCurrentHeight = (steps: Step[], isBuildingExists: boolean) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const stepsNumber = steps.length;
  let heightValue = '';
  if (viewportWidth <= 500) {
    heightValue = isBuildingExists ? `2.85rem` : `3rem`;
  } else if (viewportWidth <= 660) {
    heightValue = `calc((${isBuildingExists ? `40` : `50`}vh - 7rem) / ${stepsNumber})`;
  } else if (
    viewportWidth > 660 &&
    ((viewportWidth <= 1024 && viewportHeight > 1000) ||
      (viewportWidth < 1024 && viewportHeight < 1000))
  ) {
    heightValue = `calc((30vh - 1rem) / ${stepsNumber})`;
  } else {
    heightValue = `calc((${isBuildingExists ? `50` : `60`}vh - 7rem) / ${stepsNumber})`;
  }
  return {
    height: heightValue,
  };
};

export const goToWebsite = (host: string) => {
  window.location.href = `${window.location.protocol}//${host}`;
};

export const openMyHome = () => {
  window.location.assign(getMyHomeUrl());
};

export const getTranslationInfo = (availability: ApartmentAvailability, componentName: string) => {
  const translationInfo: TranslationInfo = { label: '' };
  if (availability.date) {
    translationInfo.label = `${componentName}.first-availability`;
    translationInfo.options = { date: availability.date };
    return translationInfo;
  } else {
    if (availability.number === 0) {
      translationInfo.label = `${componentName}.no-apartments`;
      return translationInfo;
    } else if (availability.number === 1) {
      translationInfo.label = `${componentName}.one-apartment`;
      return translationInfo;
    } else {
      translationInfo.label = `${componentName}.many-apartments`;
      translationInfo.options = { count: availability.number };
      return translationInfo;
    }
  }
};

export const getDefaultsRandom = (
  defaultTypes: DefaultTypes,
): { type: string; mood: string; building: string } => {
  const typeInd = Math.floor(Math.random() * (Object.keys(defaultTypes).length - 1));
  const type = Object.keys(defaultTypes)[typeInd];
  const moodIndex = Math.floor(Math.random() * (defaultTypes[type].mood.length - 1));
  return {
    type: type,
    mood: defaultTypes[type]['mood'][moodIndex],
    building: defaultTypes[type].buildings[0],
  };
};

export const getLabel = (name: string) => {
  if (!name) return '';
  return name.toLowerCase().replace(/\s/g, '-');
};

export const getName = (name: string) => {
  if (!name) return '';
  return name.replace(/-/g, ' ');
};

export const refreshPage = () => {
  window.location.reload();
};

export const getMonthlyPrice = (
  isTotalRateWithoutExpenses: boolean,
  rentInformation: RentState,
) => {
  return isTotalRateWithoutExpenses
    ? currencyFormatter.format(Math.round(rentInformation.totalPriceWithoutExpenses))
    : currencyFormatter.format(Math.round(rentInformation.totalPrice));
};

export default {
  getPicker,
  uppercaseFormatter,
  controlUserChoice,
  getAvailableFloors,
  getAvailablePacks,
  getSelectedPacks,
  getNextSlideIndex,
  openMyHome,
  goToWebsite,
  currencyFormatter,
  dateFormatter,
  dateFormatterLong,
  getImageNumbers,
  isMobile,
  isMobileSmall,
  isTablet,
  isTabletBig,
  includesOneOf,
  getPackName,
  getPackLabel,
  getEmailData,
  getEmailInfoData,
  getMoodName,
  getStepIndex,
  getCurrentHeight,
  getDefaultsRandom,
  getLabel,
  refreshPage,
  getMonthlyPrice,
};
