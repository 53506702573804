import { gql, WatchQueryFetchPolicy } from '@apollo/client';

const APARTMENT_DATA_TO_RETRIEVE = `
    name
    id
    order
    floor
    price
    discounted_price
    available
    active
    code
    enabled_web
    enabled_desk
    status
    movements {
      id
      status
      checkIn
      checkOut
    }
    plan
    {
    name
    id
    extraInfo
    model {
        id
        name
        visits
    }
    typology {
        id
        name
        visits
        rooms
        formulas {
          id
          increase
          name: label
          shortDescription
          description: longDescription
        }
    }
    SuperficieGiardino
    SuperficieTotale
    SuperficieTerrazzo
    SuperficieAppartamento
    SuperficieLoggia
    }
    sides {
      id
      name
      building {
        id
        name
        floors
        address
        complex
        code
        zone
        geo{
          lat
          lon
        }
      }
    }
    extraInfo
    mood
    packsList
`;

const FEATURE_DATA_TO_RETRIEVE = `
id
type
sub
building
price
expenses
extraInfo
`;

export interface ApartmentFilterInput {
  plan?: string;
  building?: string;
  available?: boolean;
  enabled_web?: boolean;
  enabled_desk?: boolean;
  complex?: string;
}

export interface SpaceFilter {
  typology?: string;
  building_id?: string;
}

export interface SpaceInRangeInput {
  startDate: string;
  endDate: string;
  types: ('APARTMENTS' | 'FEATURES' | 'ALL')[];
  excludeQuote_id?: string;
}

export interface ApartmentFilterInputOptions {
  variables: {
    project_id: string;
    appartmentInput: ApartmentFilterInput;
  };
}
export interface GetFeaturesInRangeOptions {
  variables: {
    project_id: string;
    input: SpaceInRangeInput;
    filter?: SpaceFilter;
  };
  fetchPolicy: WatchQueryFetchPolicy;
  notifyOnNetworkStatusChange: boolean;
}

export const GET_ALL_APARTMENTS = gql`
  query getAllAppartmentsPublic($project_id :ID!) {
    getAllAppartmentsPublic(project_id: $project_id) {
      ${APARTMENT_DATA_TO_RETRIEVE}
    }
}`;

export const GET_APARTMENTS_DEFAULT_OPTIONS = (pName: string) => {
  return {
    variables: {
      project_id: pName,
    },
    // fetchPolicy: 'network-only',
  };
};

export const GET_FILTERED_APARTMENTS = gql`
  query getAppartmentsFiltered($project_id :ID!, $appartmentInput: AppartmentFilterInput!) {
    getAppartmentsFiltered(project_id: $project_id, appartmentInput: $appartmentInput) {
      ${APARTMENT_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_FILTERED_APARTMENTS_DEFAULT_OPTIONS = (
  project_id: string,
  appartmentInput: ApartmentFilterInput,
): ApartmentFilterInputOptions => {
  const options: ApartmentFilterInputOptions = {
    variables: {
      project_id,
      appartmentInput,
    },
  };
  return options;
};

export const GET_FEATURES = gql`
  query getFeaturesPublic($project_id :ID!) {
    getFeaturesPublic(project_id: $project_id) {
      ${FEATURE_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_FEATURES_DEFAULT_OPTIONS = (pName: string) => {
  return {
    variables: {
      project_id: pName,
    },
  };
};

export const GET_FEATURES_IN_RANGE = gql`
  query getSpacesInRange($project_id: ID!, $input: SpaceInRangeInput!, $filter: SpaceFilter) {
    getSpacesInRange(project_id: $project_id, input: $input, filter: $filter)
  }
`;

export const GET_FEATURES_IN_RANGE_OPTIONS = (
  project_id: string,
  input: SpaceInRangeInput,
  filter?: SpaceFilter,
): GetFeaturesInRangeOptions => ({
  variables: {
    project_id,
    input,
    filter,
  },
  fetchPolicy: 'no-cache',
  notifyOnNetworkStatusChange: true,
});
