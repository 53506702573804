import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';
import datesReducer from './slices/date';
import rentReducer from './slices/rent';
import infoReducer from './slices/info';
import authReducer from './slices/auth';

const rootReducer = combineReducers({
  info: infoReducer,
  dates: datesReducer,
  rent: rentReducer,
  auth: authReducer,
});

const storeOptions = {
  reducer: rootReducer,
  // devTools: process.env.NODE_ENV !== 'production',
};

const store = configureStore({
  ...storeOptions,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  // preloadedState: loadFromLocalStorage(),
});

// store.subscribe(() => saveToLocalStorage(store.getState()));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export interface ActionReducer<T, P> {
  readonly type: T;
  readonly payload?: P;
}

export default store;
